import $ from 'jquery';
import Pubsub from 'pubsub';
import Html5Validation from '../utils/html5validation';

export default function NewsletterForm() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

NewsletterForm.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    if($(".js-newsletter-form input[name='_csrf']").length == 0){
      var form = $(".js-newsletter-form");

      var token = $("meta[name='_csrf']").attr("content");
      var csrfToken = $("<input>", {'type': 'hidden', 'name': '_csrf', 'value': token});
      form.append(csrfToken);
    }


  }
};
