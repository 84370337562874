import $ from 'jquery';
import Pubsub from 'pubsub';
import config from "../config/config";
import Html5Validation from "../utils/html5validation";

export default function AddressSuggestions() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
}

AddressSuggestions.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();
    html5validation.addValidationHandler();

    $('.js-check-address-suggestions').off('click').on('click', function(e){
      e.preventDefault()
      _this.submitRegister(_this, html5validation);
    });

    $(".js-submit-address-sugesstion").off('click').on('click', function(e) {
      e.preventDefault();
      var addressSuggestion = $("#js-address-suggestions-form");
      var choosenSuggest = addressSuggestion.find("input[type=radio]:checked");

      var addressForm = $(".js-address-form");
      addressForm.find("#street").val(choosenSuggest.attr("data-street"));
      addressForm.find("#zip").val(choosenSuggest.attr("data-zipCode"));
      addressForm.find("#city").val(choosenSuggest.attr("data-city"));

      var addressSuggestionArea = addressForm.find('input[name="addressSuggestionArea"]').val();
      if (addressSuggestionArea === 'shipping') {
        _this.submitFormShipping(addressForm);
      }
      else if (addressSuggestionArea === 'invoice') {
        _this.submitFormInvoice(addressForm);
      } else {
        addressForm.submit();
        $('#the-modal').modal('hide');
      }
    });

    if($('.js-single-suggestion input[type=radio]:checked')){
      $('.js-single-suggestion input[type=radio]:checked').closest('.form-group.radio').addClass("active");
    }

    $(".js-single-suggestion").off('click').on('click', function(e) {
      $('.js-single-suggestion input[type=radio]:checked').closest('.form-group.radio').toggleClass("active");
    });

    $(".js-close-address-suggestion").off('click').on('click', function(e) {
      e.preventDefault();
      $('#the-modal').modal('hide');
      $('.js-onlyOnce-btn-static').prop('disabled', false);
      $('.js-onlyOnce-btn-static').removeClass('disabled');
    });

  },

  submitRegister: function(_this, html5validation) {
    var addressForm = $('.js-address-form');
    var isValid = html5validation.validate(addressForm);
    if (isValid) {
      $('.js-onlyOnce-btn-static').prop('disabled', true);
      $('.js-onlyOnce-btn-static').addClass('disabled');
      var currentStreet = addressForm.find(".js-current-street").text().trim();
      var currentZipCode = addressForm.find(".js-current-zipCode").text().trim();
      var currentCity = addressForm.find(".js-current-city").text().trim();
      var currentCountry = addressForm.find(".js-current-country").text().trim();
      var street = addressForm.find('#street').val().trim();
      var zipCode = addressForm.find('#zip').val().trim();
      var city = addressForm.find('#city').val().trim();

      var country;
      var addressSuggestionArea = addressForm.find('input[name="addressSuggestionArea"]').val();
      if (addressSuggestionArea === 'catalog') {
        if (currentCountry !== '') {
          country = currentCountry;
        } else {
          country = addressForm.find('#country').val().trim();
        }
      } else {
        country = addressForm.find('#country option:selected').val().trim();
      }

      if ((currentStreet === street) && (currentZipCode === zipCode) && (currentCity === city) && (currentCountry === country)) {
        var addressSuggestionArea = addressForm.find('input[name="addressSuggestionArea"]').val();
        if (addressSuggestionArea === 'shipping') {
          _this.submitFormShipping(addressForm);
        }
        else if (addressSuggestionArea === 'invoice') {
          _this.submitFormInvoice(addressForm);
        } else {
          addressForm.submit();
          $('#the-modal').modal('hide');
        }
      } else {
        var params = {
          street: street,
          zipCode: zipCode,
          city: city,
          country: country
        }
        _this.showForm(params, _this);
      }
    } else {
      return;
    }
  },

  showForm: function(params, _this) {
    var query = $.param(params);
    $.get(config.addressSuggestionsFormUrl, params, function(data) {
      if (data.success){
        $('#the-modal').empty().html(data.markup);
        $('#the-modal').modal('show');
        Pubsub.publish("core.attach", $("#js-address-suggestions-form"));
      } else {
        var addressForm = $(".js-address-form");
        var addressSuggestionArea = addressForm.find('input[name="addressSuggestionArea"]').val();
        if (addressSuggestionArea === 'shipping') {
          _this.submitFormShipping(addressForm);
        }
        else if (addressSuggestionArea === 'invoice') {
          _this.submitFormInvoice(addressForm);
        } else {
          addressForm.submit();
        }
      }
    }).fail(function(jqXhr, error) {
      if (window.console) console.log("address suggestion overlay error!", error);
    });
  },

  submitFormShipping: function(addressForm) {
    $.post(config.checkoutShippingNewAddressChangeUrl, addressForm.serialize(), function(data) {
      if(data.success){
        window.location.href = window.location.href;
      } else {
        $('#the-modal').modal('hide');
        var checkoutShipping = $(".checkout-shipping");
        checkoutShipping.html(data.markup);
        Pubsub.publish("core.attach", checkoutShipping);
      }
    }).fail(function(jqXhr, error) {
      if (window.console) console.log("submitNewAddressButton error!", error);
    });
  },

  submitFormInvoice: function(addressForm) {
    $.post(config.checkoutInvoiceAddressChangeUrl, addressForm.serialize(), function (data) {
      if(data.success){
        window.location.href = window.location.href;
      } else {
        $('#the-modal').modal('hide');
        var checkoutShipping = $(".checkout-shipping");
        checkoutShipping.html(data.markup);
        Pubsub.publish("core.attach", checkoutShipping);
      }
    }).fail(function (jqXhr, error) {
      if (window.console) console.log("post error checkout invoice address", error);
    });
  },
};
