import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';
import Loading from '../components/loading';
import scrollToElement from '../utils/scrollTo';

export default function ActionCodeEngine() {
  Pubsub.subscribe( 'core.attach', $.proxy( this.attach, this ) );
}

ActionCodeEngine.prototype = {
  attach: function( msg, element ) {
    var _this = this;
    var container = $( ".js-action-code-engine" );

    // check config presence
    if(!config.actionCodeUrls){
      return;
    }
    this.config = config;

    if(container.data('open')) {
      container.data('open', false);
      _this.editBenefits(false);
    }

    $('#error').affix({
    });

    // Trigger for 'Vorteile übernhemen'
    $(".promotions").off('click').on("click", ".js-submitPromotionButton", function (e) {
      e.preventDefault();
      _this.applyBenefits();
    } );

    // hide attainable promos
    $(".promotions").on("click", ".js-cancelPromotionButton", function (e) {
      e.preventDefault();

      if($(".js-action-code-markup-auto").is(':empty') && $(".js-action-code-markup-selectable" ).is(':empty')) {
        _this.hideContainer();
      } else {
        $( ".not-redeem-promotions-content" ).toggleClass( "hidden" );
      }
      _this.rejectAttainBenefit();
    } );

    // trigger for 'Vorteile ändern'
    $( ".js-openBenefits" ).off( "click" ).on('click', function(e) {
      e.preventDefault();
      _this.editBenefits(true);
    } );

    // unchoose selected item
    $( ".remove-promo" ).off( "click" ).on('click', function(e) {
      e.preventDefault();
      var itemId = $(this).parents('[data-itemId]').data('itemid');
      _this.unchooseItem(itemId);
    } );

    // activate/deactivate button 'Vorteile übernehmen'
    // prevent selection of sold out variations / disabled options
    $(".promotions").off('change').on("change",
      ".redeem-promotions-content input[type='checkbox'], .redeem-promotions-content select",
       function () {
        var item = $(this).parents('[data-itemId]');
        if(item.find('option:selected').prop('disabled')){
          item.find('input[type="checkbox"]').prop('checked', false);
        }
    });

    // allow only max items on PAP selection
    $('.promotions').on('change','[data-max-items] input[type="checkbox"]', function() {
      _this.maxSelectionValidation($(this));
    });

    _this.saveActionCode($('.js-action-code-form'));

  },
  showContainer: function(markup) {
    var container = $( ".js-action-code-engine" );
    var showAuto = container.data('show-auto');
    var showSelectable = container.data('show-selectable');
    var showAttainable = container.data('show-attainable');
    var pageLoad = container.data('page-load');
    var automContainer = $( ".js-action-code-markup-auto" );
    var selectContainer = $( ".js-action-code-markup-selectable" );
    var attainContainer = $( ".js-action-code-markup-attainable" );
    var errorContainer = $(".summary-errors");

    if(markup) {
      if (pageLoad) {
        if(showAuto) {
          automContainer.html(markup.auto);
        }
        if(showSelectable){
          selectContainer.html(markup.selectable);
        }
        if(showAttainable){
          attainContainer.html(markup.attainable);
        }
    } else{
        automContainer.html(markup.auto);
        selectContainer.html(markup.selectable);
        attainContainer.html(markup.attainable);
      }
    }

    var addArticle = $(".add-article .heading");

    if(!automContainer.is(':empty') || !selectContainer.is(':empty')
        || !attainContainer.is(':empty')) {
      container.removeClass( "hidden" );
      addArticle.addClass("bordered-top xs-border");
      //scrollToElement(container, 500);

      if(errorContainer.length > 0 && !errorContainer.is(':empty')) {
        scrollToElement(errorContainer, 500);
      } else{
        scrollToElement(container, 500);
      }
    }

    container.data('page-load', false);

  },
  hideContainer: function() {
    $( ".js-action-code-markup-auto" ).html('');
    $( ".js-action-code-markup-selectable" ).html('');
    $( ".js-action-code-markup-attainable" ).html('');

    var container = $( ".js-action-code-engine" );
    var addArticle = $(".add-article .heading");

    container.addClass( "hidden" );
    addArticle.removeClass("bordered-top xs-border");
    scrollToElement($('.cart-content'), 500);
  },
  saveActionCode: function(form) {
    var _this = this;
    var input = form.find('input[name="actionCode"]');

    form.off('submit').on('submit', function(e){
      e.preventDefault();

      var formData = form.serialize();

      $.post(config.actionCodeUrls.set, formData)
      .done(function(resultJson,jqXHR) {
          if(resultJson.success) {
            $( ".js-action-code-engine" ).data('page-load', false);
            _this.showContainer(resultJson.markup);
            input.val("");
            // update basket part
            _this.updatePositionList();
            $('.js-action-code-error').html('');
          } else {
            $('.js-action-code-error').html(resultJson.markup.error);
          }
      });
    });
  },
  checkZeroBasketValue: function() {
    $('.js-basket-loader').addClass('loader');
    $.get(config.basketCheckZeroValue, function(resultJson){
      if(resultJson.success){
        window.location.reload(true);
      }
    }).always(function(){
      $('.js-basket-loader').removeClass('loader');
    });
  },
  updatePositionList: function() {
    var _this = this;
    $('.js-basket-loader').addClass('loader');
    $.get(config.basketUpdateValue, function(resultHtml){
      var newContent = $(resultHtml).find('.cart-content');
      $('.cart-content').replaceWith(newContent);

      var headerButtons = $(resultHtml).find('.header-btns')[0];
      $('.header-btns:not(.js-header-btns)').replaceWith(headerButtons);

      var errorMsg = $(resultHtml).find('.js-basket-size-error');
      $('.js-basket-size-error').replaceWith(errorMsg);

      // apply cart.js event trigger
      Pubsub.publish('core.attach');
    }).always(function(){
      $('.js-basket-loader').removeClass('loader');
      _this.checkZeroBasketValue();
    });
  },
  editBenefits: function(modify) {
    var _this = this;
    $.get(config.actionCodeUrls.getBenefits + "?modify=" + modify, function(resultJson){
      if(resultJson.success) {
        if(modify) {
          var container =  $( ".js-action-code-engine" );
          $( ".js-action-code-markup-auto" ).html(resultJson.markup.auto);
          $( ".js-action-code-markup-selectable" ).html(resultJson.markup.selectable);
          $( ".js-action-code-markup-attainable" ).html(resultJson.markup.attainable);

          if(! $( ".js-action-code-markup-auto" ).is(':empty') || ! $( ".js-action-code-markup-selectable" ).is(':empty')
              || !$( ".js-action-code-markup-attainable" ).is(':empty')) {
            container.removeClass( "hidden" );
            scrollToElement(container, 500);
          }
        } else {
          _this.showContainer(resultJson.markup);
        }

       }
    });

  },
  applyBenefits: function() {
    var _this = this;
    var selectedBenefits = $('.redeem-promotions-content input[type="checkbox"]:checked');
    var notSelectedBenefits = $('.redeem-promotions-content input[type="checkbox"]:not(:checked)');
    var basketBenefits = $('.basket-promotions-content .row.product, .basket-promotions-content .xrow.product');
    var selectedBenefitsList = [];
    var itemsInBasket = [];

    if (basketBenefits.length > 0 && notSelectedBenefits.length > 0) {
      basketBenefits.each(function() {
        itemsInBasket.push($(this).data('itemid'));
      });

      notSelectedBenefits.each(function() {
        var item = $(this).parents('[data-itemId]');
        var itemId = item.data('itemid');

        if(item && jQuery.inArray(itemId, itemsInBasket) !== -1) {
          _this.unchooseItem(itemId);
        }
      });

    }

    selectedBenefits.each(function() {
      var item = $(this).parents('[data-itemId]');

      if(item) {
        var postData = {
          'itemId': item.data('itemid'),
          'itemType': item.data('type')
        };

        var amount = item.find('select[name="prAmount"]').val();
        if(!amount){
          amount = 1;
        }

        postData['amount'] = amount;

        var variationId = item.find('select[name="promoItemSize"]').val();
        if(variationId){
          postData['itemId'] = variationId;
        }

        selectedBenefitsList.push(postData);
      }
    });

    $.ajax({
      type: 'POST',
      url: config.actionCodeUrls.select,
      data: JSON.stringify(selectedBenefitsList),
      contentType : 'application/json'
    }).always(function(){
      _this.updatePositionList();
      $( ".js-action-code-markup-selectable" ).html('');
      $( ".js-action-code-engine" ).data('page-load', false);
      window.location.reload(true);
    });
  },
  unchooseItem: function(itemId) {
    var _this = this;
    var postData = {
      'itemId': itemId
    };

    $.post(config.actionCodeUrls.unchoose, postData, function(resultJson) {
      _this.updatePositionList();
    });

  },
  maxSelectionValidation: function(elem) {
    var parent = elem.parents('[data-max-items]');
    var max = parent.data('max-items');
    var selectedCheckboxes = parent.find('input[type="checkbox"]:checked');

    if(selectedCheckboxes.length > max) {
      // unselect the first selected checkbox in this group (but not the clicked one)
      parent.find('input[type="checkbox"]:checked').not(elem).first().prop('checked', false);
    }
  },
  rejectAttainBenefit: function() {
    $.post(config.actionCodeUrls.rejectAttainBenefit);
  }
};
