import $ from 'jquery';

$.fn.lazyload = function() {
  var matched = $(this);
  matched.each(function() {
    $("img[data-src]", $(this)).each(function() {
      $(this).attr("src", $(this).attr("data-src"));
    });
  });
  return matched;
};
