/**
 * This script implements a fully client side form validation. It registers a
 * focus listener to each form input element ("input", "select") that uses the
 * CSS class "form-control", managing the live error feedback to the user. On
 * initialization it sets a placeholder value for each input not already having
 * one with the value of its label.
 * <p>
 * Each input's validation is configured by its attribute "data-restriction".
 * The following comma separated validation informations can by set:
 * <ul>
 * <li>"type" (defaults to "text")
 * <ul>
 * <li>"text"</li>
 * <li>"email" input is validated to an email address</li>
 * <li>"phone" input is validated to a phone number</li>
 * <li>"choice" input is validated to a selected value from its "option"s list.
 * The first entry is used as the placeholder</li>
 * </ul>
 * </li>
 * <li>"min" (defaults to "1"). To declare an input as optional, specify a min
 * of value 0.</li>
 * <li>"max" (defaults to Number.MAX_VALUE)</li>
 * </ul>
 * </p>
 * <p>
 * The developer is responsible for keeping the client side validation at least
 * as restrictive as the corresponding server side validation. The latter one
 * results just in true or false.
 * </p>
 */
import $ from "jquery";
import Pubsub from "pubsub";

export default function FormValidator($formControl) {
  this.$formControl = $formControl;
  this.required = true;
  this.type = 'text';
  this.min = 1;
  this.max = Number.MAX_VALUE;
  this.ref = "";

  var restrictions = this.$formControl.data('restriction');
  var that = this;
  $.each(restrictions.split(','), function(i, restriction) {
    var restrictionParts = restriction.split('=');

    var key = restrictionParts[0].trim();
    var value = restrictionParts.length > 1 ? restrictionParts[1].trim() : '';
    switch (key) {
    case 'min':
      that.min = parseInt(value);
      break;
    case 'max':
      that.max = parseInt(value);
      break;
    case 'type':
      that.type = value;
      break;
    case 'required':
      that.required = !(value == "false"); // default is true, so required
      // must be turned off
      // (required=false)
      break;
    case 'ref':
      that.ref = value;
      break;
    default:
      break;
    }
  });
}

FormValidator.prototype = {
  errorMessages: {
    'optional': 'Sie können dieses Feld auch leer lassen.',
    'text.empty': 'Angabe erforderlich.',
    'text.exact': 'Geben Sie genau %1 Zeichen ein.',
    'text.length': 'Geben Sie mindestens %1 und höchstens %2 Zeichen ein.',
    'choice.empty': 'Bitte wählen Sie.',
    'email.at': 'Die Eingabe enthält kein "@".',
    'email.domain': 'Die Eingabe enthält keine Domäne.',
    'phone.illegalchar': 'Die Telefonnummer enthält unerlaubte Zeichen. Erlaubt sind: Ziffern, +, / und Leerzeichen.',
    'phone.onlyOne': 'Die Telefonnummer darf nur ein %1-Zeichen enthalten.',
    'password.repeat': 'Geben Sie hier exakt dieselben Zeichen ein wie im Passwortfeld.',
  },
  showErrorTip: function(msgKey) {
    this.$formControl.addClass('error');
    this.$formControl.prop('validatyStatus', false);

    if (!this.$formControl.is(':focus')) {
      return;
    }

    var $errorTip = $('#' + this.$formControl.attr('id') + '\\.errors');
    if (!$errorTip.length) {
      $errorTip = $('<span>', {
        id: this.$formControl.attr('id') + '.errors'
      }).addClass('error-tip').insertAfter(this.$formControl);

      var position = this.$formControl.position();
      position.top += this.$formControl.outerHeight();
      position.top += parseInt(this.$formControl.css('marginTop'));
      $errorTip.css(position);
    }

    var message = this.errorMessages[msgKey];
    for (var i = 1; i < arguments.length; i++) {
      message = message.replace(new RegExp('%' + i, 'g'), arguments[i]);
    }
    if (!this.required) {
      message += ' ' + this.errorMessages['optional'];
    }
    $errorTip.text(message);

    var scrollTop = $(window).scrollTop();
    var innerHeight = window.innerHeight;
    var errorOffsetBottom = $errorTip.offset().top + $errorTip.outerHeight();
    if (scrollTop + innerHeight > errorOffsetBottom) {
      return;
    }

    var offset = $errorTip.offset().top + $errorTip.outerHeight() - window.innerHeight;
    $('html,body').animate({
      scrollTop: offset + 'px'
    }, 100);
  },
  hideErrorTip: function() {
    var $errorTip = $('#' + this.$formControl.attr('id') + '\\.errors');
    if ($errorTip.length) {
      $errorTip.remove();
    }
    if (this.$formControl.prop('validatyStatus')) {
      this.$formControl.removeClass('error');
    }
    this.$formControl.removeProp('validatyStatus');
  },
  validate: function() {
    if (this.type == 'choice') {
      return this.validateComboBox();
    }
    if (this.type == 'email') {
      return this.validateEmail();
    }

    if (this.type == 'phone') {
      return this.validatePhone();
    }

    if (this.type == 'text') {
      return this.validateText();
    }

    if (this.type == 'password') {
      return this.validatePassword();
    }
  },
  validateComboBox: function() {
    var $selected = $('#' + this.$formControl.attr('id') + ' option:selected');
    if ($selected.index() > 0) {
      this.$formControl.prop('validatyStatus', true);
      this.hideErrorTip();
      return;
    }

    this.showErrorTip('choice.empty');
    return;
  },
  validateEmail: function() {
    var val = this.$formControl.val();

    if (!this.required && val.length == 0) {
      this.$formControl.prop('validatyStatus', true);
      this.hideErrorTip();
      return;
    }

    if (val.length < this.min || val.length > this.max) {
      this.showErrorTip('text.length', this.min, this.max);
      return;
    }

    var indexOfAt = val.indexOf('@');
    if (indexOfAt == -1) {
      this.showErrorTip('email.at');
      return;
    }

    var indexOfDot = val.indexOf('.', indexOfAt);
    if (indexOfDot == -1) {
      this.showErrorTip('email.domain');
      return;
    }

    this.$formControl.prop('validatyStatus', true);
    this.hideErrorTip();
    return;
  },
  validatePhone: function() {
    var val = this.$formControl.val();

    if (!this.required && val.length == 0) {
      this.$formControl.prop('validatyStatus', true);
      this.hideErrorTip();
      return;
    }

    if (val.length < this.min || val.length > this.max) {
      this.showErrorTip('text.length', this.min, this.max);
      return;
    }

    for (var i = 0; i < val.length; i++) {
      var validChars = '0123456789 +/';
      if (validChars.indexOf(val[i]) == -1) {
        this.showErrorTip('phone.illegalchar');
        return;
      }
    }

    if ((val.match(/\+/g) || []).length > 1) {
      this.showErrorTip('phone.onlyOne', '+');
      return;
    }

    if ((val.match(/\//g) || []).length > 1) {
      this.showErrorTip('phone.onlyOne', '/');
      return;
    }

    this.$formControl.prop('validatyStatus', true);
    this.hideErrorTip();
    return;
  },
  validateText: function() {
    var val = this.$formControl.val();

    if (!this.required && val.length == 0) {
      this.$formControl.prop('validatyStatus', true);
      this.hideErrorTip();
      return;
    }

    if (val.length >= this.min && val.length <= this.max) {
      this.$formControl.prop('validatyStatus', true);
      this.hideErrorTip();
      return;
    }

    if (this.min == this.max) {
      this.showErrorTip('text.exact', this.min);
      return;
    }

    this.showErrorTip('text.length', this.min, this.max);
  },
  validatePassword: function() {
    this.validateText();
  // change when using password quality check
  }
};
