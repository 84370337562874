import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';

var privacy = "",
  terms = "",
  prize = "",
  description = "",
  lastPage = "",
  initialized = false;

function openModal() {
  $("#the-modal").empty().modal('show').load(config.raffleBaseUrl + "modal/?id=" + config.raffleId, startRaffle);
}

function startRaffle() {
  $.get(config.raffleBaseUrl + "page/?page=" + config.rafflePage, function(data) {
    $("#body-content-anchor").empty().html(data);
    rebindClicks();
  });
}

function continueRaffle() {
  var textarea = $("#raffle_freeText");
  if (textarea.val() === "") {
    textarea.css("border-color", "#ae3737");
    textarea.css("border-width", "3px");
    return;
  }
  $.post(config.raffleBaseUrl + "page/", $("#form").serialize(), function(data) {
    $("#body-content-anchor").empty().html(data);
    rebindClicks();
  });
}

function displayTermsAndConditionsPage() {
  if (terms === "") {
    $.get(config.raffleBaseUrl + "static/?id=" + config.rafflePage + "&page=termsAndConditions", function(data) {
      terms = data;
      displayStaticPagePostHandle(terms);
    });
  } else {
    displayStaticPagePostHandle(terms);
  }
}

function displayPrivacyPolicyPage() {
  if (privacy === "") {
    $.get(config.raffleBaseUrl + "static/?id=" + config.rafflePage + "&page=privacyPolicy", function(data) {
      privacy = data;
      displayStaticPagePostHandle(privacy);
    });
  } else {
    displayStaticPagePostHandle(privacy);
  }
}

function displayPrizeDescriptionPage() {
  if (prize === "") {
    $.get(config.raffleBaseUrl + "static/?id=" + config.rafflePage + "&page=prizeDescription", function(data) {
      prize = data;
      displayStaticPagePostHandle(prize);
    });
  } else {
    displayStaticPagePostHandle(prize);
  }
}

function displayRaffleDescriptionPage() {
  if (description === "") {
    $.get(config.raffleBaseUrl + "static/?id=" + config.rafflePage + "&page=raffleDescription", function(data) {
      description = data;
      displayStaticPagePostHandle(description);
    });
  } else {
    displayStaticPagePostHandle(description);
  }
}

function displayStaticPagePostHandle(staticpage) {
  lastPage = $("#body-content-anchor").contents();
  $("#body-content-anchor").empty().html(staticpage);
  rebindClicks();

  $("#raffle_footer_btn_terms").hide();
  $("#raffle_footer_btn_prize").hide();
  $("#raffle_footer_btn_privacy").hide();
  $("#raffle_footer_btn_description").hide();
  $("#raffle_footer_btn_back").show();
}

function returnToRaffle() {
  $("#body-content-anchor").empty().html(lastPage);
  rebindClicks();

  $("#raffle_footer_btn_terms").show();
  $("#raffle_footer_btn_prize").show();
  $("#raffle_footer_btn_privacy").show();
  $("#raffle_footer_btn_description").show();
  $("#raffle_footer_btn_back").hide();
}

function submitAddress() {
  $.post(config.raffleBaseUrl + "address/", $("#userInfo").serialize(), function(data) {
    $("#body-content-anchor").empty().html(data);
    rebindClicks();
  });
}

function addMoreRecipients() {
  var newRowTemplate = $('#raffle_show_more_recipients_source').html();
  for (var i = 0; i < 3; i++) {
    var newRow = newRowTemplate.replace(/13/g, $('#raffle-recommend .recommend-row').size());
    $('#raffle_show_more_recipients_target').before(newRow);
  }
  if ($('#raffle-recommend .recommend-row').size() >= 9) {
    $('#raffle_add_more_recipients_row').hide();
  }
  rebindClicks();
}

function submitRecommendations() {
  $.post(config.raffleBaseUrl + "recommend/", $("#raffle-recommend").serialize(), function(data) {
    $("#body-content-anchor").empty().html(data);
    rebindClicks();
  });
}

function bindClick(selector, action) {
  $(selector).off('click').on('click', function(e) {
    e.preventDefault();
    action();
  });
}

function initialize() {
  if (!initialized) {
    if (config.showRaffle) {
      openModal();
    }
    initialized = true;
  }
}

function rebindClicks() {
  bindClick("#raffle_page_next_btn, .raffle-nextPageAction", continueRaffle);
  bindClick("#raffle_footer_btn_back", returnToRaffle);
  bindClick("#raffle_footer_btn_terms", displayTermsAndConditionsPage);
  bindClick("#raffle_footer_btn_prize", displayPrizeDescriptionPage);
  bindClick("#raffle_footer_btn_privacy", displayPrivacyPolicyPage);
  bindClick("#raffle_footer_btn_description", displayRaffleDescriptionPage);
  bindClick("#submit_addressform_btn", submitAddress);
  bindClick("#raffle_add_more_recipients", addMoreRecipients);
  bindClick("#raffle_recommend_btn", submitRecommendations);
  bindClick("#raffle_address_btn_terms", displayTermsAndConditionsPage);
  bindClick("#raffle_address_btn_privacy", displayPrivacyPolicyPage);
}

export default function Raffle() {
  Pubsub.subscribe('core.attach', initialize);
}
