import $ from 'jquery';

export default function showOrHidePassword(){

  // login 3P
  $("span.js-show-password-login").off("click").on("click", function(e) {
    showOrHidePasswordLogin3P ("#inputPassword", "text", "span.js-show-password-login", "span.js-hide-password-login");
  });
  $("span.js-hide-password-login").off("click").on("click", function(e) {
    showOrHidePasswordLogin3P ("#inputPassword", "password", "span.js-hide-password-login", "span.js-show-password-login");
  });

  function showOrHidePasswordLogin3P (pw, type, hide, show){
    $(pw).attr("type", type);
    $(hide).hide();
    $(show).show();
  };

  // login Vima
  $("span.js-show-password-login-mobile, span.js-show-password-login-desktop").off("click").on("click", function(e) {
    showOrHidePasswordLoginVima ("#inputPasswordMobile", "#inputPasswordDesktop",
      "text", "span.js-show-password-login-mobile, span.js-show-password-login-desktop",
      "span.js-hide-password-login-mobile, span.js-hide-password-login-desktop");
  });
  $("span.js-hide-password-login-mobile, span.js-hide-password-login-desktop").off("click").on("click", function(e) {
    showOrHidePasswordLoginVima ("#inputPasswordMobile", "#inputPasswordDesktop",
      "password", "span.js-hide-password-login-mobile, span.js-hide-password-login-desktop",
      "span.js-show-password-login-mobile, span.js-show-password-login-desktop");
  });

  function showOrHidePasswordLoginVima (pwMobile, pwDesktop, type, hide, show){
    $(pwMobile).attr("type", type);
    $(pwDesktop).attr("type", type);
    $(hide).hide();
    $(show).show();
  };

  // register
  $("span.js-show-password-register").off("click").on("click", function(e) {
    showOrHidePasswordRegister ("#password1", "text", "span.js-show-password-register", "span.js-hide-password-register");
  });
  $("span.js-hide-password-register").off("click").on("click", function(e) {
    showOrHidePasswordRegister ("#password1", "password", "span.js-hide-password-register", "span.js-show-password-register");
  });

  function showOrHidePasswordRegister (pw1,type, hide, show){
    $(pw1).attr("type", type);
    $(hide).hide();
    $(show).show();
  };

}
