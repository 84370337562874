import $ from 'jquery';
import Pubsub from 'pubsub';
import Forms from '../utils/forms';
import config from '../config/config';
import Loading from '../components/loading';
import Html5Validation from '../utils/html5validation';


export default function ShopRaffle() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
  this.loading = new Loading();
}

ShopRaffle.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    const next = Array.from(document.querySelectorAll('.js-raffle-next'));
    const back = Array.from(document.querySelectorAll('.js-raffle-back'));

    next.forEach((elem, index) => {
      elem.addEventListener("click", (e) => {
      if ( document.querySelector('.raffle-imageupload input') !== null ) {
        $('.layer.active .js-raffle-freetext-error').hide();
        if (document.querySelector('.raffle-imageupload input').value === ''){
          $('.js-raffle-image-error').show();
          return;
        }
      }
      if ( document.querySelector('.layer.active .raffle-freetext textarea') !== null ) {
        $('.layer.active .js-raffle-freetext-error').hide();
        if (document.querySelector('.layer.active .raffle-freetext textarea').value.trim() === ''){
          $('.layer.active .js-raffle-freetext-error').show();
          return;
        }
      }
      if (Array.from(document.querySelectorAll('.layer.active  .js-raffle-question input')).length > 0 ) {
        $('.layer.active .js-raffle-question-error').hide();
        var checked = false;
        Array.from(document.querySelectorAll('.layer.active .js-raffle-question input')).forEach(e => {
          if (e.checked) {
            checked = true;
          }
        });
        if ( !checked ){
          $('.layer.active .js-raffle-question-error').show();
          return;
        }
      }
      $(e.target).closest('.layer').removeClass('active');
      if (index === (next.length)) {
        document.querySelector('.layer.js-raffle-address').classList.add('active');
      } else{
        $(e.target).closest('.layer').next().addClass('active');
      }
      });
    });

    back.forEach((elem, index) => {
      elem.addEventListener("click", (e) => {
        $(e.target).closest('.layer').removeClass('active');
      if (index === (back.length)) {
        document.querySelector('.js-raffle-last-elem').classList.add('active');
      } else{
        $(e.target).closest('.layer').prev().addClass('active')
      }
      });
    });

    // check raffle type errors before submit
    $('.shop-raffle form').on('submit',function(e){
      var everything_correct = true;
      Array.from(document.querySelectorAll('.layer')).forEach((e) => {
        // check freetext
        var maxLength = $('textarea[name=freetextList]').data('maxlength');
        if(e.querySelector('.raffle-freetext') !== null){
          if (e.querySelector('.raffle-freetext textarea').value.trim() === '' || e.querySelector('.raffle-freetext textarea').value.length > maxLength){
            everything_correct = false;
            $('.js-raffle-freetext-error').show();
          }
        }
        // check image
        if(e.querySelector('.raffle-imageupload') !== null){
          if (e.querySelector('.raffle-imageupload input').value === ''){
            everything_correct = false;
            $('.js-raffle-image-error').show();
          }
        }
        //check questions
        if(e.querySelector('.js-raffle-question') !== null){
          var checked = false;
          Array.from(e.querySelectorAll('.js-raffle-question input')).forEach(e => {
            if (e.checked) {
              checked = true;
            }
          });
          if (!checked) {
            everything_correct = false;
            $('.layer.active .js-raffle-question-error').show();
          }
        }
      });
      if (everything_correct) {
        Array.from(document.querySelectorAll('.shop-raffle textarea')).forEach(e => {e.value=(e.value.split(',').join('##'))});
      } else {
        console.info('error');
        e.preventDefault();
      }
    });

    var customBtn = $('#js-custom-button');
    var realFileBtn = $('#js-image-upload');

    customBtn.off("click").on("click", function(e) {
      $(realFileBtn).click();
    });

    realFileBtn.on("change", function(e) {
      console.log(realFileBtn);
      if(realFileBtn[0].value) {
        $('#custom-text').text(realFileBtn[0].value);
      }
    });

    if ( window.FileReader && window.File && window.FileList && window.Blob ){
      $('#js-image-upload').bind('change', function() {
        $('.js-raffle-image-error').hide();
        if (this.files[0].size > 10485760 || !['image/png','image/gif','image/jpeg'].includes(this.files[0].type) || this.files[0].name.length > 60) {
          $('.js-raffle-image-error').show();
          document.querySelector('#js-image-upload').value = null;
        }
      });
    }

  },

};
