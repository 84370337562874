import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';
import Loading from '../components/loading';
import CartInput from '../components/cart_input';
import log from 'consolelog';
import '../utils/form_elements/plusminus';
import 'bootstrap';
import Html5Validation from "../utils/html5validation";

export default function Cart() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));

  this.loading = new Loading();
  this.cart_input = new CartInput();
  this.notepad_input = new CartInput(".notepad-header");
}

Cart.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    $(".btn-basket-artno").off('click').on('click', function() {
      $(this).addClass('loader');
      var prodId = $("#input-basket-artno").val();
      prodId = encodeURIComponent(prodId);
      if (prodId != 'undefined' && prodId != null) {
        var requestUrl = config.userBasketInteractionDirectProductAddPath + "?productId="+ prodId;
        $.post(requestUrl, function (response) {
          $(".btn-basket-artno").removeClass('loader');
          $(".js-basket-artno").html(response.markup);
          if($('.basket-art-no.single-article input:checked')){
            $('.basket-art-no.single-article input:checked').parent().addClass("active");
          }
          Pubsub.publish("core.attach", $(".js-basket-artno"));
          $('#productSize').on('change', function() {
            $("#input-itemId").val(this.value);
          });
        }).fail(function (response) {
          $(this).removeClass('loader');
          window.console && console.log(response);
        })
      }else{
        $(this).removeClass('loader');
      }
    });

    var custChoiceContent = $(".checkout-basket").find(".customer-choice").children();
    $(".checkout-basket").find(".js-customer-choice").append(custChoiceContent);

    $(".js-show-art-direct").off('click').on('click', function() {
      $(".js-show-art-direct").addClass("hidden");
      $(".js-show-art-direct-hide").removeClass("hidden");
      $(".js-show-art-direct-input").show();
    });

    $(".js-show-art-direct-hide").off('click').on('click', function() {
      $(".js-show-art-direct-hide").addClass("hidden");
      $(".js-show-art-direct").removeClass("hidden");
      $(".js-show-art-direct-input").hide();
    });

    $('#error').affix({
    });

    $(".js-input-basket-artno").change(function() {
      var alertMsg = $(".js-basket-artno").find(".alert");
      var inputVal = $(".js-input-basket-artno").val();
      if (alertMsg.length && !inputVal){
        alertMsg.remove();
      }
    });

    $('#productQuantity').off("change").on('change', function(e) {
      e.preventDefault();
      var data = $("#article-form").serialize();

      $.post(config.amountChangePath, data)
       .done(function(result) {
         if(result.success){
           var newContent = $(result.markup);
           $('.js-price-info').replaceWith(newContent);
         }
       });
     });

    $(".js-input-direct").off("click").on("click", function() {
      $(this).addClass("loader");
      $.post($("#article-form").attr("action"), $("#article-form").serialize(), function(data) {
        if(!data.success) {
          var selector = $('.js-ptext-error');
          selector.html(data.errorsMarkup);
          $('.js-input-ptext').each(function(i, obj){
            var _this = this;
            if($(_this).val().length > $(_this).data('maxlength')){
              $(_this).addClass('has-error')
              $('.js-ptext-error').css("padding-bottom","15px");
            }
          });
        } else {
          window.location.reload(true);
        }
      }).always(function(){
        $(".js-input-direct").removeClass("loader");
      });
    });

    $("#article-form [data-update]").off("click").on("click", function(e) {
      $(".js-basket-artno").addClass('loader');
      var $element = $(e.target);
      var update = $element.serialize();
      var itemId = $("#input-itemId").serialize();
      var prodId = $("#input-basket-artno").val();
      var size = $element.find('.js-size').val();
      var sizeName = $element.find('.js-size').attr('name');
      var requestUrl = config.userBasketInteractionDirectProductAddPath + "?productId="+ prodId + '&' + update + '&' + itemId + '&' +escape(sizeName) +"="+ size
      $.post(requestUrl, function (response) {
        $(".js-basket-artno").html(response.markup);
        $("label[for='" + $element.attr('id') + "']").addClass('active');
        $(".js-basket-artno").removeClass('loader');
        Pubsub.publish("core.attach", $(".js-basket-artno"));
      }).fail(function (response) {
        $(".js-basket-artno").removeClass('loader');
        window.console && console.log(response);
      });
    });

    $(".js-input-customers-choice").off("click").on("click", function(e){
      e.preventDefault();
      $(this).addClass("loader");
      $.post($("#js-customers-choice-form").attr("action"), $("#js-customers-choice-form").serialize(), function(data) {
        window.location.reload(true);
      }).fail(function (response) {
        window.console && console.log(response);
        $(".js-input-customers-choice").removeClass("loader");
      })
    });

    $(".js-input-basket-artno").off('keydown').on('keydown', function(e) {
      var keyCode = e.which;
      if (e.type == "keydown" && keyCode != 13 && keyCode != 9) {
        return;
      }
      e.preventDefault();
      $('.js-button-basket-artno').click();
    });

    // Voucher code - add
    const voucherFrom = $('.js-voucher-code-form');
    voucherFrom.off("submit").on("submit", function(e){
      e.preventDefault();

      const formData = voucherFrom.serialize();
      $(".js-vouchercode-message").html("");
      $.post(config.voucherCodeUrl, formData)
        .done(function(data) {
          if(data.success) {
            window.location.href = window.location.href;
          } else {
            $(".js-vouchercode-message").html(data.error);
            $(".js-vouchercode-message").css("display", "block");
            $("input[name='voucherCode']").addClass("has-error");
          }
        }).fail(function(error) {
          if (window.console) console.error("Failed to add voucher code!", error);
        });
    });

    // Voucher code - remove
    const voucherRemoveFrom = $('.js-voucher-code-form-remove');
    voucherRemoveFrom.off("submit").on("submit", function(e) {
      e.preventDefault();
      $.post(config.voucherCodeRemoveUrl)
        .done(function(data) {
          if(data.success) {
            window.location.href = window.location.href;
          }
        }).fail(function(error) {
        if (window.console) console.error("Failed to remove voucher code!", error);
      });
    });

    $('.js-input-ptext').off('keydown').on('keydown', function(e) {
      var keyCode = e.which;
      if (e.type == "keydown" && keyCode == 13) {
        e.preventDefault();
        return false;
      }
    });

    var _this = this;

    $(".all-in-cart").off("click").on("click", function(e) {
      $.post($("#all-in-cart-form").attr("action"), $("#all-in-cart-form").serialize(), function(data) {
        //
        _this.cart_input.input(data.markup, data.count, data.total, null, null);
      }).fail(function(jqXhr, e) {
        log(e);
      });
    });

    $(".actions .to-cart a").off("click").on("click", function(e) {
      e.preventDefault();
      var $link = $(this);
      $.get($(this).attr("href"), function(data) {
        _this.cart_input.input(data.markup, data.count, data.total, null, null);
      }).fail(function(jqXhr, e) {
        log("Add to card/notepad failed.", e);
      });
    });

    var wishlistPopup = $('#wishlist-popover');
    if (wishlistPopup.length != 0) {
      var data = JSON.parse(wishlistPopup.html());
      _this.notepad_input.input(data.markup, data.count, data.total);
    }

    var togglePopupVisibility = function (target, targetIndex, cartPopupVisibility) {
      if(targetIndex.toString() === cartPopupVisibility.toString()) {
        $(target).show();
      } else {
        $(target).hide();
      }
    }
    $(".js-popup-close").each(function () {
      let visibility;
      if (window.matchMedia("(max-width: 767px)").matches) {
        visibility = '0';
      } else {
        visibility = '1';
      }
      togglePopupVisibility($(this).closest(".cart-popup"), $(this).data("visibility"), visibility);
    })
    $(".js-popup-close").off("click").on("click", function(e) {
      $(".js-popup-close").each(function () {
        togglePopupVisibility($(this).closest(".cart-popup"), $(this).data("visibility"), e.currentTarget.dataset.self);
      })
    });
  },
  responsive: function(msg, on_off) {
    switch (msg) {
    case 'res.xs':
      if (on_off) { // entered state xs
        this.removeSwipes();
        $(".offers .swipe, .sellers .swipe").Swipe({
          pair: 2
        }).knobs().lazy();
      } else { // left state xs

      }
      break;
    case 'res.sm':
      if (on_off) { // entered state sm

        this.removeSwipes();
        $(".offers .swipe, .sellers .swipe").Swipe({
          pair: 3
        }).knobs().lazy();
      } else { // left state sm

      }
      break;
    default:
      if (on_off) {
        this.removeSwipes();
        $(".offers .swipe, .sellers .swipe").Swipe({
          pair: 4,
          continuous: false
        }).controls().lazy();
      }
    }
  },
  removeSwipes: function() {
    $(".swipe").each(function() {
      if ($(this).data("Swipe")) $(this).data("Swipe").kill();
    });
  }
};
