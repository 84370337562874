import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';

export default function tracking_GA() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('tracking.productListClick', $.proxy(this.product_list_click, this));
  Pubsub.subscribe('tracking.pageViewAjaxEvent', $.proxy(this.submit_pageview, this));
  Pubsub.subscribe('tracking.layer.trackingEvent', $.proxy(this.track_event, this));
}

tracking_GA.prototype = {
  attach: function(msg, element) {
    var _this = this;
    if (element === document) {
      $(document).ready(function() {

        _this.submit_pageview();

        // on promo click
        $(".promo-data").on("mousedown", "a.promolink", function(e) {
          var $this = $(this);

          var techId = $this.closest(".promo-data").data("analytics-tid");
          var promoItem = $.grep(_this.teaser_collection, function(e){ return e.tId === techId; });

          if(typeof promoItem !== 'undefined' && promoItem.length > 0) _this.promo_click(promoItem);
        });

        $(document).on("click",".click-event-area", function(e) {
          var $this = $(this);
          var tracking_group = $this.data("analytics-group");
          var tracking_id = $this.data("analytics-tid");
          var target = $this.data("target");
          var isNonInteractive = ($this.data("analytics-interactive") !== 'undefined') ? "0" : "1";
          var trackingData = { "eventCategory" : "ClickEvent", "eventAction" : tracking_group,
            "eventLabel" : tracking_id, "eventNonInteractive" : isNonInteractive};

          location.href=target;
          _this.track_event("tracking.layer.trackingEvent",trackingData);
        });
        // collect all teaser
        _this.collect_teaser();
        _this.submit_promotionview();
      });
    }
  },
  product_list_click: function(msg, element) {
    var itemId = element.data("tritem");
    var position_index = element.data("trpos");
    var list = element.data("trlist");

    var postData = {
        'productList': list,
        'itemId': itemId,
        'position': position_index
      };

    this.post_to_backend(config.listClickUrl, postData);
  },
  submit_pageview: function() {
    var pageName = 'undefined';
    if(typeof trackingData !== 'undefined'){
      for (var key in trackingData) {
        if (trackingData.hasOwnProperty(key)) {
          var prop = trackingData[key];
          for (var element in prop) {
            if (prop.hasOwnProperty(element)) {
              if (element === 'pagename') {
                pageName = prop[element];
              }
            }
          }
        }
      }
    }

    if(pageName === 'undefined'){
      var path = window.location.pathname;
      path = path.replace("/3pagen", "");
      if (path.indexOf("/p/") === -1) path = path.replace(/^\/|\/$/g, '');
      var gaParams = location.search.replace("?","").split("&").filter(
        function(i){if(i.indexOf('utm_') === 0 || i.indexOf('gclid=') === 0 || i.indexOf('dclid=') === 0)
        {return i}}).join('&');
      gaParams = gaParams.length > 0 ? '?' + gaParams : '';
      pageName = path + gaParams;
    }

    var endTime = window.performance.timing.domContentLoadedEventEnd;
    var startTime = window.performance.timing.navigationStart;
    if (endTime <= 0) {
      endTime = Date.now();
    }
    var loadTime =  endTime - window.performance.timing.navigationStart;

    var pageViewData = {
        'pageName': pageName,
        'pageTitle': document.title,
        'referrer': document.referrer,
        'loadTime': loadTime
      };

    this.post_to_backend('/ajax/tracking/page-view/', pageViewData);
  },
  consentCookieExists: function() {
    return document.cookie.includes("ConsentCookie=");
  },
  post_to_backend: function(targetUrl, postData) {
     $.ajax({
       type: 'POST',
       url: targetUrl,
       data: JSON.stringify(postData),
       contentType: 'application/json'
     });
  },
  collect_teaser: function() {
    var _this = this;
    _this.teaser_collection = [];
    $('[data-analytics-promotion]:visible').each(function(i,e){
      var trackingElement = $(e);

      if (trackingElement.data("analytics-promotion")) {
        var trackparams = {}, params = trackingElement.data("analytics-promotion").split("&");
        for (var param in params) {
          if (params.hasOwnProperty(param)) {
            var _a = params[param].match("([^=]+)=([^&]+)");
            if (_a[0] && _a[1] && _a[2])
              trackparams[_a[1]] = _a[2];
          }
        }

        var s = trackingElement.closest(".promo-data").data("analytics-position");
        var n = s.indexOf('_');
        var teaser_position = s.substring(0, n !== -1 ? n : s.length);
        var techId = trackingElement.closest(".promo-data").data("analytics-tid");

        var promoJSON = {
            id: trackparams["id"],
            name: trackparams["name"],
            creative: trackparams["creative"],
            position: teaser_position,
            tId : techId
        };

        // 2 stage teaser tabs result in 4 elements, the following ensures unique elements in collection array
        if($.grep(_this.teaser_collection, function(e){ return e.tId === techId; }).length === 0){
          // register impression/teaser
          _this.teaser_collection.push(promoJSON);
        }
      }
    });
  },
  submit_promotionview: function() {
    var _this = this;
    var promotionViewData = [];
    _this.teaser_collection.forEach(function (item) {
      var promotion = {
          promoId: item["id"],
          promoName: item["name"],
          promoCreative: item["creative"],
          promoPosition: item["position"]
      };
      promotionViewData.push(promotion);
    });

    if (promotionViewData.length > 0) {
      this.post_to_backend('/ajax/tracking/promotion-view/', promotionViewData);
    }
  },
  track_event: function(pubSubCall,trackingData) {
    var _this = this;

    if (trackingData != null) {
      var postData = {
        'eventCategory' : trackingData.eventCategory,
        'eventAction' : trackingData.eventAction,
        'eventLabel' : trackingData.eventLabel,
        'eventNonInteractive' : trackingData.eventNonInteractive
      }
      var trackingUrl = (config.trackEventUrl == undefined) ? "/ajax/tracking/tracking-event/" : config.trackEventUrl;
      
      _this.post_to_backend(trackingUrl, postData);
    }
  },
  promo_click: function(promoItem) {
    var promotionViewData = [];

    if(typeof promoItem !== 'undefined'){
      for (var key in promoItem) {
        if (promoItem.hasOwnProperty(key)) {
          var prop = promoItem[key];
          var promotion = {
              promoId: prop["id"],
              promoName: prop["name"],
              promoCreative: prop["creative"],
              promoPosition: prop["position"]
          };
          promotionViewData.push(promotion);
        }
      }
    }

    if (promotionViewData.length > 0) {
      this.post_to_backend('/ajax/tracking/promotion-click/', promotionViewData);
    }
  },
  teaser_collection: []
};
