// Everything that should happen on every page
import $ from 'jquery';
import Pubsub from 'pubsub';
import Offcanvas from '../components/offcanvas';
import TrackingHelper from '../utils/trackinghelper';
import 'bootstrap';

export default function Navi() {
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
  this.offcanvas = new Offcanvas('navi-offcanvas');
  $(window).on("resize", $.proxy(this.resizeHandler, this));
}

Navi.prototype = {
  responsive: function(msg, on) {
    var _this = this;
    var hoverTimer = null;

    if (on) {
      // remove arrows
      if ($('.js-navi-mobile-header').length) {
        $('.navi-offcanvas .glyphicon-3P-arrow-right').remove();
      } else {
        $('.navi-offcanvas .glyphicon-arrow-right').remove();
      }

      // remove meta menu
      $('.meta-li').remove();
    }
    // desktop
    if (on && (msg === "res.md" || msg === "res.lg" || ($('.js-navi-mobile-header').length > 0 && msg === "res.sm"))) {
      this.offcanvas.switchIt(false);

      // hide flyout
      $('[data-js=navi-menu] li ul').hide();
      // set height
      if (this.heightTimeout) {
        clearTimeout(this.heightTimeout);
      }
      $('.navi-offcanvas').height('auto');
      // mouse over menu
      var hoverTimer = null;

      $('[data-js=navi-menu] > ul > li').off("mouseover.levelone").on("mouseover.levelone", function() {
        var __this = this;
        // for de/at
        if ($('.js-navi-mobile-header').length > 0 ) {
          if (msg === "res.sm") {
            $('.js-navi-teaser').css('display','none');
          } else {
            $('.js-navi-teaser').css('display','block');
          }
        }
        if (hoverTimer) clearTimeout(hoverTimer);
        hoverTimer = setTimeout(function() {
          _this.updateFlyout(__this);
          _this.showFlyout(__this);
          _this.setFlyoutImage(__this);
        }, 250);
      });
      // mouse over submenu
      $('[data-js=navi-content-1]').off("mouseover", "a").on('mouseover', 'a', function() {
        // do not remove category teaser image in de/at
        if ($('.js-navi-teaser').length < 1) {
          _this.showFlyoutSub(this);
          _this.setFlyoutImage($(this).parent());
        }
      });
      // mouse over sub-submenu
      $('[data-js=navi-content-2]').off("mouseover", "a").on('mouseover', 'a', function() {
        _this.setFlyoutImage($(this).parent());
      });
      // mouse over menu content
      $('[data-js=navi-content]').off("mouseover.submenu").on("mouseover.submenu", function() {
        var __this = this;

        if (hoverTimer) clearTimeout(hoverTimer);
        hoverTimer = setTimeout(function() {
          _this.showFlyout(__this);
        }, 250);
      });
      // mouse out menu + menu content
      $('[data-js=navi-menu], [data-js=navi-content]').off("mouseout").on("mouseout", function() {
        var __this = this;

        if (hoverTimer) clearTimeout(hoverTimer);
        hoverTimer = setTimeout(function() {
          _this.hideFlyout(__this);
        }, 250);
      });
      //Tracking
      $('[data-js=navi-menu] a').off('click').on('click', function() {
        //Tracking
        var trackinghelper = new TrackingHelper();
        trackinghelper.event('', 'Navigation_' + $(this).attr('href') + '_klick', 'Navigation');
      });
    // smartphone & tablet
    } else if (on && (msg === 'res.xs' || ($('.js-navi-mobile-header').length < 0 && msg === 'res.sm'))) {
      // toggle offcanvas
      var _this = this;
      _this.offcanvas.switchIt(true);
      // add meta menu
      $('.meta-menu .user li').each(function() {
        $('[data-js=navi-menu-ul]').append($(this).clone().addClass('meta-li'));
      });
      // add arrows
      $('.navi-offcanvas li').not('.menu-back-1, .menu-back-2').has('> ul').each(function() {
        if ($('.js-navi-mobile-header').length) {
          $(this).append('<span class="glyphicon glyphicon-3P-arrow-right pull-right"></span>');
        } else {
          $(this).append('<span class="glyphicon glyphicon-arrow-right pull-right"></span>');
        }
      });
      // set height
      if (this.heightTimeout) {
        clearTimeout(this.heightTimeout);
        this.heightTimeout = null;
      }
      this.heightTimeout = setTimeout(function() {
        $('.navi-offcanvas').height($(document).height());
      }, 2000);
      //
      $('.navbar-header [data-toggle=offcanvas]').off("click").on("click", function(e) {
        e.preventDefault();
        _this.offcanvas.toggle();
        // show submenu
        $('.navi-offcanvas li').off('click').on('click', function(e) {
          e.stopPropagation();
          if ($(this).children().is('ul')) {

            var menu_level = $(this).parent('ul').first().hasClass('scroll-container') ? 1 : 2;

            $('> ul', this).addClass('shop-navigation-content').show();
            $('.menu-back-' + menu_level + ' a').text($('> a', this).text());
            if ($('.js-navi-mobile-header').length) {
              $('.js-navi-mobile-header').addClass('hidden');
              $('.js-navi-mobile-footer').addClass('hidden');
            }
            $(this).parents('[data-js=navi-menu-ul]').animate({
              left: '-=' + ($('> ul', this).width())
            }, 'fast');
            $("html, body").animate({
              scrollTop: 0
            }, 200);

            //Tracking
            var trackinghelper = new TrackingHelper();
            trackinghelper.event('', 'Navigation_' + $('a', this).attr('href') + '_klick', 'Navigation');

            return false;
          } else {
            window.location.href = $("a", this).attr("href");

            //Tracking
            var trackinghelper = new TrackingHelper();
            trackinghelper.event('', 'Navigation_' + $('a', this).attr('href') + '_klick', 'Navigation');
          }
        });

        // back menu level
        var goBack = function(e) {
          e.preventDefault();
          var $backBtn = $(this);
          $(this).parents('[data-js=navi-menu-ul]').animate({
            left: '+=' + ($('.navi-offcanvas ul ul:visible').first().width())
          }, 'fast', function() {
            if ($backBtn.hasClass('menu-back-2')) $('.navi-offcanvas ul ul ul:visible').hide();
            else {
              if ($('.js-navi-mobile-header').length) {
                $('.js-navi-mobile-header').removeClass('hidden');
                $('.js-navi-mobile-footer').removeClass('hidden');
              }
              $('.navi-offcanvas ul ul:visible').hide();
            }
          });
          $("html, body").animate({
            scrollTop: 0
          }, 200);
        };
        $('.menu-back-1, .menu-back-2').off('click').on('click', goBack);

      });
      $('.js-navi-close').off('click').on('click', function(e) {
        e.preventDefault();
        _this.offcanvas.hide();
      });

    }
  },
  resizeHandler: function(e) {
    if (this.offcanvas.active()) {
      $('.navbar-header [data-toggle=offcanvas]').click();
      $('[data-js=navi-menu-ul]').removeAttr('style');
      $('body > .navi-offcanvas ul ul').css('display','none');
    }
  },
  updateFlyout: function(e) {
    $('[data-js=navi-content] ul, [data-js=navi-title-1], [data-js=navi-title-2]').empty();
    $('[data-js=navi-title-1]').text($('> a', e).text());
    $('[data-js=navi-content-1]')
      .html($('> ul', e).clone().show())
      .show();

    //Tracking
    $('[data-js=navi-content-1] a').off('click').on('click', function() {
      var trackinghelper = new TrackingHelper();
      trackinghelper.event('', 'Navigation_' + $(this).attr('href') + '_klick', 'Navigation');
    });
  },
  showFlyout: function(e) {
    if ($('ul', e)[0]) {
      // hover
      if ($(e).is('div')) {
        /* active auch mit hover
         $('[data-js=navi-menu-ul] > li')
         .eq($(e).attr('data-js-menu-hover'))
         .addClass('hover');
         */
      } else {
        $('[data-js=navi-content]').attr('data-js-menu-hover', $(e).index());
        $('[data-js=navi-menu] .hover').removeClass('hover');
        $(e).has('> ul').not('.active').addClass('hover');
        $($(e).parents('header')).addClass('open');
        var offsetMenuEntry = $( e ).position();
        var leftOffsetNaviContent=offsetMenuEntry.left+5;
        var naviContentWidth=$('[data-js=navi-content]').width();
        var mainNavRightEnd=$('.js-main-nav').width();
        //override wider offsets then the end of main navi
        if (leftOffsetNaviContent+naviContentWidth>mainNavRightEnd){
          leftOffsetNaviContent=mainNavRightEnd-naviContentWidth;
        }
        $('[data-js=navi-content]').css('left', leftOffsetNaviContent);
      }
      $('[data-js=navi-content]').show();
    } else {
      this.hideFlyout();
    }
  },
  hideFlyout: function(e) {
    $('[data-js=navi-content]').hide();
    $('[data-js=navi-menu] .hover').removeClass('hover');
    $(e).parents('header').removeClass('open');
  },
  showFlyoutSub: function(e) {
    $('[data-js=navi-content-1] .active').removeClass('active');
    if ($(e).next('ul')[0]) {
      $('[data-js=navi-title-2]')
        .text($(e).text())
        .show();
      $('[data-js=navi-content-2]')
        .html($(e).next('ul').clone().show())
        .show();
      if($('[data-js=navi-content-2]').css('display') == 'block'){
        $('[data-js=navi-content-1]').addClass("has-second-level");
        $('[data-js=navi-content-2]').addClass("border-left");
      }
      $(e).addClass('active');
    } else {
      $('[data-js=navi-title-2], [data-js=navi-content-2]').hide();
      $('[data-js=navi-content-1]').removeClass("has-second-level");
      $('[data-js=navi-content-2]').removeClass(".border-left");
    }

    //Tracking
    $('[data-js=navi-content-2] a').off('click').on('click', function() {
      var trackinghelper = new TrackingHelper();
      trackinghelper.event('', 'Navigation_' + $(this).attr('href') + '_klick', 'Navigation');
    });
  },
  setFlyoutImage: function(e) {
    $('.navi-content img').parent().remove();
    $('.navi-content img').remove();

    if ($(e).is('[data-navimg]')) {
      $(
      '<img/>', {
          src: $(e).attr('data-navimg')
        }
      ).appendTo('.navi-content--teaser');

      $('.navi-content img').wrap('<a href="' + $(e).attr('data-navurl') + '" target="'+ $(e).attr('data-navtarget') + '" width="'+ $(e).attr('data-navwidth') + '" height="'+ $(e).attr('data-navheight') + '" ></a>');
    }
  }
};
