// Everything that should happen on every page
import $ from 'jquery';
import Pubsub from 'pubsub';
import Affiliate from './affiliate';
import config from '../config/config';
import 'suggest';
import '../../libs/jquery.rwdImageMaps'
import '../../libs/jquery.imagemapster'
import scrollToElement from "../utils/scrollTo";
import showOrHidePassword from "../components/password";
import Html5Validation from "../utils/html5validation";

export default function General() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.resxs, this));
}

General.prototype = {
  attach: function(msg, element) {
    var html5validation = new Html5Validation();

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    $('.js-image-map--res').rwdImageMaps();

    showOrHidePassword();

    let fillcolor = 'FFFFFF';
    let strokecolor = 'FFFFFF';
    const wrapper = $('.js-image-map-wrapper');
    if(wrapper.length) {
      fillcolor = wrapper.data("fillcolor");
      strokecolor = wrapper.data("strokecolor");
    }

    $('.js-image-mapster').mapster({
      mapKey: 'reference',
      fillColor: fillcolor,
      fillOpacity: 0.25,
      stroke: false,
      strokeColor: strokecolor,
      strokeOpacity: 0.8,
      strokeWidth: 1.5,
      clickNavigate: false,
      staticState: false
    });

    $(".js-image-map--tooltip").off("click").on("click", "area", function(e) {
      e.preventDefault();

      if (e.which === 1) {
        // only on left mouse down
        var element = $(e.target);
        var productid = element.data("productid");
        var onlyRemove = false;

        if (productid) {
          // hide and remove all opened popovers
          $(".popover").each(function () {
            var el =  $(this);
            var popoverProductId = el.find(".shop-look-popover-content-wrapper").data('productid');
            if (productid === popoverProductId) {
              onlyRemove = true;
            }
            el.popover('hide');
            el.remove();
          });

          if (!onlyRemove) {
            $.get(config.shopLookUrl + "?productId=" + productid, function (data) {
              if (data.success === true || data.success === 'true') {
                const elm = $(".js-image-map-wrapper");
                const popOverContent = data.markup;
                const popoverHeight = -50;
                const popoverWidth = 40;
                let x = e.pageX - elm.offset().left + popoverWidth;
                const y = e.pageY - elm.offset().top + popoverHeight;
                const placement = e.pageX > ($(document).width() / 2) ? 'left' : 'right';

                if (placement === 'left') {
                  x = x - popoverWidth - 260;
                }

                element.popover({
                  placement: placement,
                  html: true,
                  trigger: "manual",
                  content: popOverContent
                }).on("shown.bs.popover", function() {
                  $(this).next().css({top: y, left: x});
                });
                element.popover('show')
              } else {
                // If popover fails, navigate to pdp
                window.location.href = element.attr("href");
              }
            });
          }
        } else {
          // if no product id is given, navigate to link
          window.location.href = element.attr("href");
        }
      }
    });

    //cerberus
    $('.account-login.account-content article.content .gt-login-nxt').attr('data-cerberus','login-submit');
    $('.account-login.account-content .row.account-content .gt-login-nxt').attr('data-cerberus','account_creation-submit');
    $('.account-content.register .gt-login-nxt').attr('data-cerberus','account_creation-submit');

    $('.js-onlyOnce-form-static').submit(function(){
     $('.js-onlyOnce-btn-static').prop('disabled', true);
     $('.js-onlyOnce-btn-static').addClass('disabled');
    });


    if ($(".js-lastseen-products .article").length < 1) {
      $(".js-lastseen-products").remove();
    }

    $(".js-last-seen-remove").off("click").on("click", function(e) {
      e.preventDefault();

      var productId = $(this).data('productid');
      if (productId != 'undefined' && productId != null) {
        var requestUrl = config.lastSeenRemoveProductUrl + "?productId=" + productId;
        $.post(requestUrl, function(response) {
          $(".js-lastseen-products").html(response.markup);
          $(".js-lastseen-products label.bottom").click();
          Pubsub.publish("core.attach", $(".js-lastseen-products label.bottom"));
        }).fail(function(response) {
          window.console && console.log(response);
        })
      }
    });


    $(".js-softlogin-close").off("click").on("click", function(e) {
      e.preventDefault();
      $.post(config.closeSoftLogin, function(resultJson) {
        if (resultJson.success) {
          $(".js-header-softlogin").html('');
        }
      });
    });

    $(".js-need-register").off("click").on("click", function(e) {
      e.preventDefault();
      $('.tab-register').click();
      $('#email').focus();
    });

    $("body", element).off("click").on("click", ".js-priceInfoModal", function(e) {
      e.preventDefault();
      var $element = $(e.target);
      var content = '.' + $element.data('content');
      $('#generic-modal').html('');
      $(content).find('.x-style.x-dialog').clone().appendTo($('#generic-modal'));
      $('#generic-modal').modal('show');
    });

    $(".js-openGenericModal", element).off("click").on("click", function(e) {
      e.preventDefault();
      var $element = $(e.target);
      var content = '.' + $element.data('content');
      $('#the-modal').html('');
      $(content).find('.x-style.x-dialog').clone().appendTo($('#the-modal'));
      $('#the-modal').modal('show');
    });

    $(".js-openVideoModal", element).off("click").on("click", function(e) {
      e.preventDefault();
        var $element = $(e.target);
        var content = '.' + $element.data('content');
        const theModal = $('#the-modal');
        theModal.html('');
        $(content).first('.modal-dialog').clone().appendTo(theModal);
        theModal.modal('show');
        var target = $('#iframe_video').attr('data-src');
        $('#iframe_video').attr('src',target);
      });


    $('#the-modal').on('hide.bs.modal', function (e) {
      $('#the-modal').html('');
    })

    $(".js-open-smallModal", element).off("click").on("click", function(e) {
      e.preventDefault();
      $(this).parents('.smallModalWrapper').find(".smallModal").show();
    });

    $(".js-close-smallModal", element).off("click").on("click", function(e) {
      e.preventDefault();
      $(this).parents('.smallModalWrapper').find(".smallModal").hide();
    });

    $(document).ready(function() {
      var once = $('body').data('once');
      var test = typeof once === 'undefined';

      var language;
      if (navigator.languages) {
          language = navigator.languages[0];
      } else {
          language = navigator.userLanguage || navigator.language;
      }
      var shopCountry = config.shopCountry;

      var countryOverlay = $('.js-country-switch');
      var basketOverlay = $('[class^="basket-hint-"]');
      var promoOverlay = $('.js-promotion-hint');
      if ((countryOverlay.length > 0) && (!once || typeof once === 'undefined') && (shopCountry === 'de') && (language.toLowerCase() === 'de-at')) {
        $('body').data('once','true');
        if (basketOverlay.length > 0) {
          basketOverlay.closest($('.modal-dialog')).remove();
        } else if (promoOverlay.length > 0) {
          promoOverlay.closest($('.modal-dialog')).remove();
        } else {
          var trackingData = {
            'eventCategory' : "ViewEvent",
            'eventAction' : "Layer",
            'eventLabel' : "CountrySwitcher",
          }
          Pubsub.publish('tracking.layer.trackingEvent', trackingData);
        }
        $('#the-modal').modal('show');


      } else if (promoOverlay.length > 0 && (!once || typeof once === 'undefined')) {
        removeCountrySwitchOverlay();
        $('#the-modal').modal('show');
        $('body').data('once','true');

      } else if (basketOverlay.length > 0 && (!once || typeof once === 'undefined')) {
        removeCountrySwitchOverlay();
        $('body').data('once','true');
        loadBasketPositionList();
        $('.js-shopcart-close').off("click").on("click", function(e) {
          clearBasketPositions(e);
        });
      }

      if (typeof genericFrontendModel !== 'undefined') {
        Pubsub.publish('generic.model.handler', genericFrontendModel);
      }

      //remove overlay if country == language in browser
      function removeCountrySwitchOverlay() {
        if (countryOverlay.length > 0 && shopCountry === language.slice(-2).toLowerCase()) {
          countryOverlay.closest($('.modal-dialog')).remove();
        }
      }

      $('a[href=]').addClass('disabled');

      $('a[href=]').on('click', function(e){
        e.preventDefault();
        return false;
      });

      function loadBasketPositionList() {
        var requestUrl = config.basketGetPath;
        $.post(requestUrl, function(response) {
          $('.js-basket-positionlist').html(response.markup);
        }).fail(function(response) {
          window.console && console.log(response);
        }).done(function() {
          $('#the-modal').modal('show');
        });
      };

      function clearBasketPositions(e) {
        var requestUrl = config.clearBasketPositionsPath;
        $.post(requestUrl).done(function() {
            window.location.reload();
          });
      };
      if ($('.js-scroll-erp-error').length > 0) {
        scrollToElement($('.js-scroll-erp-error'), 500);
      }
    });

    $(".js-info-modal").off("click").on("click", function(e) {
      $('#the-modal').html($('.js-last-seen-modal-content').html());
      $('#the-modal').modal('show');
    });

    if ($('.js-country-msg').is(":visible")) {
      $('.js-submit-check').attr("disabled",true);
    }else{
      $('.js-submit-check').attr("disabled", false);
    }

    var country = document.getElementById("country");
    var mcsCountry = $('#country').data('country');
    var optionCountry = $('#country').val();
    if (mcsCountry !== optionCountry){
      verifyCountry();
      $('#zip').addClass('no-validate');
    }else{
      $('#zip').removeClass('no-validate');
    }

    $('#country').off('change').on('change', function(){
      verifyCountry();
      var mcsCountry = $('#country').data('country');
      var optionCountry = $('#country').val();
      if (mcsCountry !== optionCountry){
        $('#zip').addClass('no-validate');
      }else {
        $('#zip').removeClass('no-validate');
      }
      $('#zip').trigger('blur');
    });

    $('#the-modal').on('shown.bs.modal', function (e) {
      var modalName = $("#the-modal").find(".view-layer").data("analytics-tid");
      if (modalName != undefined) {
        var trackingData = {
          'eventCategory' : "ViewEvent",
          'eventAction' : "Layer",
          'eventLabel' : modalName
        }
        Pubsub.publish('tracking.layer.trackingEvent',trackingData);
      }
    });

    $(".banderole").on("click", ".js-check-newsletter", function(e) {
      e.preventDefault();

      $.post(config.subscribeNewsletterUrl)
        .done(function(data) {
          if (data.success) {
            window.location.href = window.location.href;
          }
        }).fail(function(error) {
        if (window.console) console.error("Failed to subscribe newsletter", error);
      });
    });

    function verifyCountry(){
      var mcsCountry = $('#country').data('country');
      var optionCountry = $('#country').val();
      $(optionCountry).attr('selected', true);
      $('.js-country-msg').hide();
      var country = document.getElementById("country");
      var error = false;
      $('.js-submit-check').attr("disabled", false);

      if (country.value !== undefined && country.value.length === 0) {
        $('.js-country-msg').hide();
        return;
      }
      if (mcsCountry !== optionCountry){
        error = true;
        $('.js-submit-check').attr("disabled",true);
      }
      if (error) {
        $('.js-country-msg').show();
      }
    }

    if ($('.js-bday-msg').length > 0) {
      document.getElementById("birthDay").onchange = function() {
        verifyBirthdate()
      };
      document.getElementById("birthMonth").onchange = function() {
        verifyBirthdate()
      };
      document.getElementById("birthYear").onchange = function() {
        verifyBirthdate()
      };
    }

    function verifyBirthdate() {
      $('.js-bday-msg').hide();
      var d = document.getElementById("birthDay");
      var m = document.getElementById("birthMonth");
      var y = document.getElementById("birthYear");
      var error = false;

      if (d.value.length === 0 || m.value.length === 0 || y.value.length === 0) {
        return;
      }
      var d_int = parseInt(d.value, 10),
        m_int = parseInt(m.value, 10),
        y_int = parseInt(y.value, 10);
      var date = new Date(y_int, m_int - 1, d_int);
      var newDate = ('0' + date.getDate()).slice(-2) + '.' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
      var orgDate = ('0' + d.value).slice(-2) + '.' +
        ('0' + m.value).slice(-2) + '.' + y.value;
      if (newDate !== orgDate) {
        error = true;
      }

      var toDay = new Date();
      var date18 = new Date((toDay.getFullYear() - 18), toDay.getMonth(), toDay.getDate());
      if (date18 < date) {
        error = true;
      }

      if (error) {
        $('.js-bday-msg').show();
      }
    }

    if (!navigator.userAgent.match(/mobile/i)) {
      var tels = $("a[href^='tel:']");
      tels.each(function() {
        var content = $(this).html();
        $(this).parent().html(content);
      });
    }

    $("body").popover({
      placement: "right",
      html: true,
      trigger: "hover click",
      selector: '.text-tooltip'
    }).off("shown.bs.popover.text").on("shown.bs.popover.text", function() {
      var popover = $(this).next();
      $("body > *").on("click.popclose", function(e) {
        $("body > *").off("click.popclose");
        popover.popover("hide");
      });
      //Move 5px up
      popover.css({
        top: parseInt($(this).next().css("top"), 10) - 5 + "px"
      });
    });

    $('body').on('show.bs.popover','.text-tooltip', function () {
      $('body').find('.text-tooltip').not(this).each(function(){
        if($(this).next('.popover').length > 0){
            $(this).popover('hide')
        }
      })
    })

    $('.mobile-search').on('show.bs.collapse', function() {
      $('.searchbox-header button').remove();
    });

    $(window).off("scroll.gotop").on("scroll.gotop", function() {
      if ($(this).scrollTop() > 400) {
        $('.go-top').fadeIn(200);
      } else {
        $('.go-top').fadeOut(200);
      }
    });

    //Add classes to accordions
    var addClasses = function(e) {
      $(this).parents(".panel").find(".panel-collapse").each(function() {

      $('.footer-panels div[data-toggle="collapse"]').click(function(e){
      if ($(window).width() >= 769) {
        e.stopPropagation();
      }
    });

        if ($(this).hasClass("in")) {
          $(this).prev().addClass("panel-in");
        } else {
          $(this).prev().removeClass("panel-in");
        }
      });
      if (e.type == "show") {
        $(this).prev().addClass("panel-in");
      }
    };
    $('.collapse').off('show.bs.collapse.classes hidden.bs.collapse.classes').on('show.bs.collapse.classes hidden.bs.collapse.classes', addClasses);
    $('.collapse.in').trigger('show.bs.collapse.classes');

    // prepare print
    if ($('table.printOrderTable').length > 0) {
      $('table.printOrderTable').prependTo('body');
      $('.table-order-result').html($('.order-conf-info').html());
      $('div, .go-top').addClass("hidden-print");
      $('.table-order-result').removeClass("hidden-print");
    }


    if (element == document) {
      new Affiliate();

      this.openHash();
      window.onhashchange = this.openHash;
      var _this = this;
      //Special case if hash stays same
      $("a[href^=#]").click(function(e) {
        if ($(this).attr("href") == window.location.hash) {
          _this.openHash();
        }
      });
    }

    // payment page terms and conditions
    $(".js-openPaymentTermsAndConditions").off("click").on("click", function(e) {
      e.preventDefault();

      $.get(config.agbModalUrl, function(data) {
        $('#the-modal').html(data.markup);
        $('#the-modal').modal('show');
      }).fail(function(jqXhr, error) {
        if (window.console) console.log("payment page terms and conditions error!", error);
      });
    });

    // service and shipping overlay
    $("#openServiceAndshipping, .js-openServiceAndShipping").off("click").on("click", function(e) {
      e.preventDefault();
      $.get(config.serviceAndShippingModalUrl, function(data) {
        $('#the-modal').html(data.markup);
        $('#the-modal').modal('show');
      }).fail(function(jqXhr, error) {
        if (window.console) console.log("service and shipping overlay error!", error);
      });
    });

    // privacy overlay
    $("#openPrivacy").off("click").on("click", function(e) {
      e.preventDefault();
      $.get(config.privacyModalUrl, function(data) {
        $('#the-modal').html(data.markup);
        $('#the-modal').modal('show');
      }).fail(function(jqXhr, error) {
        if (window.console) console.log("privacy overlay error!", error);
      });
    });

    //Hide alerts, not remove
    $(".alert .close").off("click").on("click", function(e) {
      e.preventDefault();
      $(this).parent().hide();
    });

    // Animate the scroll to top
    $('.go-top').off("click").on("click", function(event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: 0
      }, 300);
    });

    // Show/hide account flyout
    $( ".js-header-account-flyout" ).mouseenter(function() {
      $('.js-header-account-flyout-link').addClass('hover');
      $('.js-header-account-flyout-wrapper').css('display','block');
    }).mouseleave(function() {
      $('.js-header-account-flyout-wrapper').css('display','none');
      $('.js-header-account-flyout-link').removeClass('hover');
    });

    //Modals, several fixes
    $('.modal').off('show.bs.modal').on('show.bs.modal', function() {

      var measureScrollBar = function() {
        var scrollDiv = document.createElement('div');
        scrollDiv.style.cssText = 'width: 1px;height: 1px;overflow: scroll;position: absolute;top: -9999px;';
        document.body.appendChild(scrollDiv);
        var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        return scrollbarWidth;
      };

      if (this.clientHeight <= window.innerHeight) {
        return;
      }
      var scrollbarWidth = measureScrollBar();
      if (scrollbarWidth) {
        $(document.body).css('padding-right', scrollbarWidth);
      }
    }).off('hidden.bs.modal').on('hidden.bs.modal', function() {
      $("#page .twerked").removeClass('twerked').prop("disabled", false);
      $("html").removeClass("modal-open");
      $(document.body).css('padding-right', 0);
    });

    $(".js-seobox-text-more", element).off("click").on("click", function(e) {
      e.preventDefault();
      $('.js-seobox-text').css('height','100%');
      $(this).parent().hide();
      $('.js-seobox-text-less').parent().show();
    });

    $(".js-seobox-text-less", element).off("click").on("click", function(e) {
      e.preventDefault();
      $('.js-seobox-text').css('height','110px');
      $(this).parent().hide();
      $('.js-seobox-text-more').parent().show();
    });

    // overlay buttons
    if (($(window).height() < 474) && ($(window).width() < 1024) && ($(window).width() > 768) || ($(window).height() < 734)) {
      $('.goto-feedback').css('top', $('header').parent().height() + 100 + 25 + 7);
    }

    //google analytics cookie
    $( document ).ready(function() {
      var gaProperty = config.trackingID;
      var disableStr = 'ga-disable-' + gaProperty;

      if (document.cookie.indexOf(disableStr + '=true') > -1) {
        window[disableStr] = true;
        $('.js-tracking-deact-success').show();
      } else {
        $('.js-tracking-deact').show();
      }

      $('.js-tracking-cookie').off("click").on("click", function(event) {
        event.preventDefault();
        document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
        window[disableStr] = true;
        $('.js-tracking-deact').hide();
        $('.js-tracking-deact-success').show();
      });
    });

    $(".js-alert-form").on("keydown",function(){
      $('.js-alert').hide();
    });

    $("span.button-checkout-secondary.js-scroll-new-customer").off("click").on("click", function(e) {
      e.preventDefault();
      scrollToElement($(".new-customer-scroll"), 500);
    });

    // suggest
    function escapeRegExChars(value) {
      return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    var firstEvent =  $('.search .form-control:not(.js-suggestions-input)').autocomplete({
      maxHeight: 600,
      minChars: 2,
      serviceUrl: $('.search .form-control').attr('data-js-suggest'),
      paramName: 'q',
      containerClass: 'well suggest',
      transformResult: function(response) {
        var jsonResponse = $.parseJSON(response);
        return {
          suggestions: $.map($.merge($.merge($.merge($.merge(
            jsonResponse.suggestions.topLevelCategorySuggestions,
            jsonResponse.suggestions.topSearchSuggestions),
            jsonResponse.suggestions.categorySuggestions),
            jsonResponse.suggestions.productSuggestions),
            jsonResponse.suggestions.topArticleSuggestions),
            function(dataItem) {
              return {
                value: dataItem.value,
                data: dataItem.data,
                title: dataItem.title,
                categoryName: dataItem.categoryName,
                link: dataItem.link,
                imageLink: dataItem.categoryName,
                brand: dataItem.brand,
                product: dataItem.product,
                category: dataItem.category,
                topArticle: dataItem.topArticle,
                categoryEncodedPath: dataItem.categoryEncodedPath
              };
            })
        };
      },
      onSearchComplete: function(event, ui) {
        if($('.well.suggest .autocomplete-suggestion').length > 1){
          $('.well.suggest .autocomplete-suggestion[data-index="0"]').attr('search_engine-suggestion-block_1');
          $('.well.suggest .autocomplete-suggestion[data-index="2"]').attr('search_engine-suggestion-block_2');
        }
      },

      formatResult: function(suggestion, currentValue) {
        var result = "";
        var pattern = '(' + escapeRegExChars(currentValue) + ')';
        var content = suggestion.value.replace(new RegExp(pattern, 'gi'), '<strong>$1<\/strong>');
        var conf = config;
        var fallbackImgLink = conf.assetsUrl +'/../novosales/images/fallback/ads_thumb/fallback_image.png';
        var errorLink = "this.onerror=null;this.src='" + fallbackImgLink + "';";
        if (!suggestion.data || ($.trim(suggestion.data) === "")) {
          return content;
        }

        if (suggestion.data && !suggestion.categoryName) {
          result += content + " " + suggestion.data;

        } else if (suggestion.topArticle == false && suggestion.categoryName) {
          result += content + '<span class="brand-name"> in ' + suggestion.categoryName + '</span>';
        } else if (suggestion.topArticle == true && suggestion.data){
          result += '<span class="search-suggest-top-article" data-cerberus="search_engine-top_article-suggestion_1-name"><img onerror=' + errorLink + ' src="'+ suggestion.imageLink + '"></span><span class="search-suggest-top-article search-suggest-top-article-description" data-cerberus="search_engine-top_article-suggestion_1-name">'+ content +'</span><span class="search-suggest-top-article" data-cerberus="search_engine-top_article-suggestion_1-price"><b>'+ suggestion.data +'</b></span>';
        }
        return result;
      },

      onSelect: function(suggestion) {
        if (!suggestion.link || ($.trim(suggestion.link) === "")) {
          if (!suggestion.categoryEncodedPath || ($.trim(suggestion.categoryEncodedPath) === "")) {
            $('input[name="qc"]').remove();
          } else {
            $('input[name="qc"]').val(suggestion.categoryEncodedPath);
          }
          $('#search_id_form').submit();
        } else {
          window.location.href = suggestion.link;
        }
      },
      headline: function(suggestion) {
        var result = "";
        if ((suggestion.brand == true || suggestion.category == true || suggestion.product == true) && suggestion.topArticle == false && suggestion.title && ($.trim(suggestion.title) !== "")) {
          result = '<div class="header-divider-title">' + suggestion.title + '</div>';
        } else if (suggestion.title && ($.trim(suggestion.title) !== "") && !suggestion.categoryName) {
          result = '<div class="divider"></div>';
        } else if (suggestion.topArticle == true && suggestion.title && $.trim(suggestion.title) !== ""){
          result = '<div class="header-divider-title seach-suggest-article-top-head" data-cerberus="search_engine-top_article-title">' + suggestion.title + '</div>';
        }
        return result;
      }
    });

//    $.when.apply(this, firstEvent).done(function() {
//      $('.search--inputbox').on('change keydown keyup', function(){
//        if($('.well.suggest .autocomplete-suggestion').length > 1){
//          $('.well.suggest .autocomplete-suggestion[data-index="0"]').attr('search_engine-suggestion-block_1');
//          $('.well.suggest .autocomplete-suggestion[data-index="2"]').attr('search_engine-suggestion-block_2');
//        }
//      });
//    });

//    $('.search--inputbox').on('change', function(){
//      if($('.well.suggest .autocomplete-suggestion').length > 1){
//        $('.well.suggest .autocomplete-suggestion[data-index="0"]').attr('search_engine-suggestion-block_1');
//        $('.well.suggest .autocomplete-suggestion[data-index="2"]').attr('search_engine-suggestion-block_2');
//      }
//    });
//    $('.search--inputbox').on('keydown', function(){
//      if($('.well.suggest .autocomplete-suggestion').length > 1){
//        $('.well.suggest .autocomplete-suggestion[data-index="0"]').attr('search_engine-suggestion-block_1');
//        $('.well.suggest .autocomplete-suggestion[data-index="2"]').attr('search_engine-suggestion-block_2');
//      }
//    });
//    $('.search--inputbox').on('keyup', function(){
//      if($('.well.suggest .autocomplete-suggestion').length > 1){
//        $('.well.suggest .autocomplete-suggestion[data-index="0"]').attr('search_engine-suggestion-block_1');
//        $('.well.suggest .autocomplete-suggestion[data-index="2"]').attr('search_engine-suggestion-block_2');
//      }
//    });

  },
  resxs: function(msg, onoff) {
    var _this = this;

    if (msg == "res.xs") {
      if (onoff) {
        $('.footer-panels .panel .panel-collapse').removeClass('in');
        $('.footer-panels .panel .panel-collapse').css('height','auto');
        $('.footer-panels .panel .panel-heading').removeClass('panel-in');
        $('.footer-panels .panel .panel-collapse').first().addClass('in');
        $('.footer-panels .panel .panel-heading').first().addClass('panel-in');
      }
    } else {
      if (onoff) {
        $('.footer-panels .panel .panel-collapse').addClass('in');
        $('.footer-panels .panel .panel-heading').addClass('panel-in');
      }
    }

    if (msg == "res.xs") {

      // Show more link on SEO content
    if ($('.site-information').length > 0 && $('.js-seobox-text').length <= 0){

      $('.js-seo-more').parent().show();

      // SEO mouse reaction
      $(".js-seo-more").off("click").on("click", function(e) {
        e.preventDefault();
        $('.js-seo-text').removeClass("mobile-single-row");

        $(this).parent().hide();
        $('.js-seo-less').parent().show();
      });

      $(".js-seo-less").off("click").on("click", function(e) {
        e.preventDefault();
        $('.js-seo-text').addClass("mobile-single-row");
        $(this).parent().hide();
        $('.js-seo-more').parent().show();
      });
    }

      if (onoff) {
        // preventing account flyout on mobile
        $('.js-header-account-flyout').on("touchstart", function(e) {
          $(".js-header-account-flyout").unbind('mouseenter mouseleave');
        });

        var search_form = $("header .search-form").html();
        if (search_form != "") {
          $(".mobile-search .collapse-wrapper").html(search_form);
          $("header .search-form").empty();
          Pubsub.publish("core.attach", $(".mobile-search .collapse-wrapper"));
          $(".mobile-search .glyphicon").removeClass("glyphicon-search").addClass('glyphicon-arrow-right');
          $("body").popover('destroy');
          $("body").popover({
            placement: "bottom",
            html: true,
            trigger: "click",
            selector: '.text-tooltip'
          }).off("shown.bs.popover.text").on("shown.bs.popover.text", function() {
            var popover = $(this).next();
            $("body > *").on("click.popclose", function(e) {
              $("body > *").off("click.popclose");
              popover.popover("hide");
            });
            //Move 5px up
            popover.css({
              top: parseInt($(this).next().css("top"), 10) - 5 + "px"
            });
          });
        }
      } else {
        var search_form = $(".mobile-search .collapse-wrapper").html();
        if (search_form != "") {
          $("header .search-form").html(search_form);
          $(".mobile-search .collapse-wrapper").empty();
          Pubsub.publish("core.attach", $("header .search-form"));
          $("header .search-form .glyphicon").removeClass("glyphicon-arrow-right").addClass('glyphicon-search');
        }
      }
      $('.suggest').remove();
    }
  },
  openHash: function() {
    var regex = /(_[0-9]{0,})$/;
    if (document.location.hash && regex.test(document.location.hash)) {
      var matches = document.location.hash.match(regex);
      var _i = matches[0];

      $(".panel-heading").not(".panel-in").find("a[href=#collapse" + _i + "]").click();
    }
  },
  setBrowserCookie: function(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
      }
    document.cookie = name+"=" + value+expires + ";path=/";
   }
};
