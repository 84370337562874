import $ from 'jquery';
import Pubsub from 'pubsub';
import 'smartresize';
import 'skidder';

export default class SliderTeaser {
  constructor() {
    Pubsub.subscribe('core.attach', $.proxy(this.attach, this));

    this.init = false;
  }

  attach(msg, element) {
    if (!this.init) {
      this.init = true;

      this.initSlider();
    }
  }

  initSlider() {
    $('.slideshow').each(function() {
      var $slideshow = $(this);

      $slideshow.skidder({
        slideClass: '.slide',
        animationType: 'css',
        scaleSlides: true,
        maxWidth: 1300,
        maxHeight: 500,
        paging: true,
        autoPaging: true,
        pagingWrapper: ".skidder-pager",
        pagingElement: ".skidder-pager-dot",
        swiping: true,
        leftaligned: false,
        cycle: true,
        jumpback: false,
        speed: 400,
        autoplay: false,
        autoplayResume: false,
        interval: 4000,
        transition: "slide",
        afterSliding: function() {},
        afterInit: function() {}
      });

    });

    $(window).smartresize(function() {
      $('.slideshow').skidder('resize');
    });
  }
}
