import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';
import ModalFns from '../utils/modalfns';

export default function Feedback() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('feedback.attach', $.proxy(this.attach, this));
}

Feedback.prototype = {
  attach: function(msg, element) {
    var _this = this;

    $("#openFeedback").off("click").on("click", function(e) {
      e.preventDefault();
      _this.openModal(_this);
    });

    $("#submitFeedback").off("click").on("click", function(e) {
      e.preventDefault();
      _this.submitForm(_this);
    });
  },

  openModal: function(_this) {
    $("#the-modal").empty().modal().load(config.feedbackModalUrl, function() {
      _this.showForm(_this);
      Pubsub.publish("feedback.attach", $("#the-modal"));
    });
  },

  showForm: function(_this) {
    $.get(config.feedbackFormUrl, function(data) {
      $("#feedback-content-anchor").empty().html(data);
      Pubsub.publish("feedback.attach", $("#feedback-content-anchor"));
    });
  },

  submitForm: function(_this) {
      $("#submitFeedback").addClass('loader');
      var $form = $("form#feedbackForm");
      $.post(config.feedbackSubmitUrl, $form.serialize(), function(data) {
        $("#feedback-content-anchor").empty().html(data);
        Pubsub.publish("feedback.attach", $("#feedback-content-anchor"));
      }).always(function() {
        $("#submitFeedback").removeClass('loader');
      });
  },

};
//Extend Modal Functions
Feedback.prototype = Object.assign(Feedback.prototype, ModalFns.prototype);
