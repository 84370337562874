import $ from 'jquery';
import Pubsub from 'pubsub';


export default function Phone() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
}

Phone.prototype = {
  attach: function(msg, element) {
    var _this = this;

    $(".js-phone").off('keydown').on('keydown', function(e) {
      if (e.which === 32) {
        e.preventDefault();
        return;
      }
    });

    $(".js-phone").off('blur').on('blur', function(e) {
      e.preventDefault();
      const phone = $(e.target);
      const phoneVal = phone.val();
      if (phoneVal.charAt(0) == 0) {
        const newPhoneVal = phoneVal.substring(1, phoneVal.length);
        $(phone).val(newPhoneVal);
        return;
      }
    });

  }
};
