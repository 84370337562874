//Transform Tabs to collapsible elements and vice versa
import $ from 'jquery';
import 'bootstrap';

var defaults = {
  collapse: true
};

class Ttcol {

  constructor(el, options) {
    var coll = options.collapse;
    this.el = el;

    if (coll && el.hasClass('ttcol-processed')) {
      this.expand();
    }

    if (coll) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  collapse() {
    var el = this.el;

    el.addClass("ttcol-processed");
    el.find(".panel-collapse").removeClass("in");
    el.find('.tab-pane.active').removeClass("active").first().find(".panel-collapse").addClass("in");
    el.find('.tab-pane').removeClass("tab-pane").find(".panel-collapse").addClass("collapse");
  }

  expand() {
    var el = this.el;

    el.removeClass("ttcol-processed");
    var id = $(".in", el).attr("id");
    el.find('.panel-collapse').removeClass("in, collapse").attr("style", "").closest(".panel").addClass("tab-pane");

    var active_ref;
    if (id)
      active_ref = "#" + id.replace("-pane", "");
    else
      active_ref = "";

    $('a[href="' + active_ref + '"]').tab("show");
    el.find('.tab-pane').removeClass("active");
    $(active_ref).addClass("active");
  }
}

$.fn.ttcol = function(options) {
  options = Object.assign(defaults, options);
  var matched = $(this);
  matched.each(function() {
    new Ttcol($(this), options);
  });
  return matched;
};
