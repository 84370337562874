import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../components/loading';
import config from '../config/config';

export default function Coupon() {
  Pubsub.subscribe("core.attach", $.proxy(this.attach, this));
  this.loading = new Loading();
}

Coupon.prototype = {
  attach: function(msg, element) {
    var _this = this;

    $(".coupon-used .remove", element).off("click").on("click", function(e) {
      e.preventDefault();
      _this.loading.load();
      $.post(config.couponPath, {
        code: $("#used_coupon_code").val(),
        q: "coupon_input",
        task: "remove"
      }, function(data) {
        _this.loading.stop();
        $(".coupon-wrapper").replaceWith(data.markup);
        Pubsub.publish("core.attach", $(".coupon-wrapper"));
      }).fail(function(error) {
        if (window.console) console.log(error);
      });
    });

    var couponEnter = function(e) {
      if (e.keyCode && e.keyCode != 13) return;
      e.preventDefault();

      if ($(".coupon-input").val() == "") {
        $(".coupon-input").parent().addClass("has-error");
        return;
      }
      _this.loading.load();
      $.post(config.couponPath, {
        code: $(".coupon-input").val(),
        q: "coupon_input",
        task: "input"
      }, function(data) {
        _this.loading.stop();

        $(".coupon-wrapper").replaceWith(data.markup);

        if (!data.success) {
          $(".coupon-wrapper .form-error").show();
          $(".coupon-input").parent().addClass("has-error");
        }
        Pubsub.publish("core.attach", $(".coupon-wrapper"));
      }).fail(function(error) {
        if (window.console) console.log(error);
      });
    };

    $(".coupon-input", element).off("keydown").on("keydown", function(e) {
      if (e.keyCode && e.keyCode == 13) {
        couponEnter(e);
      }

    });
    $(".coupon-btn", element).off("click").on("click", couponEnter);
  },
  use: function(code) {}
};
