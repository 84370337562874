import $ from 'jquery';
import { Validator } from '../forms';

$.fn.plusminus = function() {
  var matched = $(this);

  matched.each(function() {
    var element = $(this);
    var validator = new Validator(element);
    if ($(this).find("#order-count").attr("data-min")){
      var minNumber = $(this).find("#order-count").attr("data-min");
    } else {
      var minNumber = 0;
    }
    if($(this).find("#order-count").attr("data-max")){
      var maxNumber = $(this).find("#order-count").attr("data-max");
    } else {
      var maxNumber = 0;
    }

    $(this).find("[data-value]").off("mousedown").on("mousedown", function(e) {
      e.preventDefault();
      mousedown($(this), element, minNumber, maxNumber);
    });
    element.find("input").off("change").on("change", function() {
      validator.validate(true, true);
    });
  });

  function mousedown($btn, $el, $minNumber ,$maxNumber) {
    var validator = new Validator($el);

    if (isNaN(parseInt($el.find("input").val()))) {
      validator.validate(true, true);
      return;
    }

    var add_value = parseInt($btn.attr("data-value"));
    var current_value = parseInt($el.find("input").val());
    var new_value = current_value + add_value;

    if(($minNumber > 0 && new_value < $minNumber) || ($maxNumber > 0 && new_value > $maxNumber)){
      $el.find("input").val(current_value);
    } else {
      $el.find("input").val(new_value);
      if (!validator.validate(false, true)) $el.find("input").val(current_value);
      else validator.validate(true, true);
    }
  }

  return matched;
};
