import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../components/loading';
import Html5Validation from '../utils/html5validation';

export default function Account() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
  this.loading = new Loading();
}

Account.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    this.correctSliders = function() { //compensate for widths with decimal fractions in sliders
      var w = $(".slider-container").width();
      var hw = Math.floor(w / 2) + (w % 2);
      $(".toggle.handle").width(hw);
      $(".toggle.no").width(w - hw - 2);
      var yesToggles = $(".toggle.yes").width(w - hw - 2);
      //reset toggle position as well in resize!
      $(".slider").each(function(i) {
        if ((this.style.left !== "") && (parseInt(this.style.left) != 0)) { //the slider is in the left position
          this.style.left = "-" + yesToggles.get(i).style.width;
        }
      });
    }

    if($('.js-bday-msg').length > 0) {
      document.getElementById("birthDay").onchange = function() {verifyBirthdate()};
      document.getElementById("birthMonth").onchange = function() {verifyBirthdate()};
      document.getElementById("birthYear").onchange = function() {verifyBirthdate()};
    }

    function verifyBirthdate() {
        $('.js-bday-msg').hide();
        var d = document.getElementById("birthDay");
        var m = document.getElementById("birthMonth");
        var y = document.getElementById("birthYear");
        var error = false;

        if(d.value.length === 0 || m.value.length === 0 || y.value.length === 0){
          return;
        }
        var d_int = parseInt(d.value, 10),
            m_int = parseInt(m.value, 10),
            y_int = parseInt(y.value, 10);
        var date = new Date(y_int, m_int - 1 , d_int);
        var newDate = ('0' + date.getDate()).slice(-2) + '.'
                    + ('0' + ( date.getMonth() + 1 )).slice(-2) + '.' + date.getFullYear();
        var orgDate = ('0' + d.value).slice(-2) + '.'
                    + ('0' + m.value).slice(-2) + '.' + y.value;
        if(newDate !== orgDate) {error = true;}

        var toDay = new Date();
        var date18 = new Date ((toDay.getFullYear() - 18), toDay.getMonth(), toDay.getDate());
        if(date18 <= date) {error = true;}

        if(error){
          $('.js-bday-msg').show();
        }
    }

    $(window).off("resize").on("resize", function() { //corrects a bug that occurs of the overall width of the slider container is odd (one pixel line of the red slider appears if slider is in 0 position)
      _this.correctSliders();
    });
    this.correctSliders(); //correct the initial slider size set by the css
  },

  responsive: function(msg, on_off) {
    switch (msg) {
    case 'res.xs':
      if (on_off) { // entered state xs
      } else { // left state xs

      }
      break;
    case 'res.sm':
      if (on_off) { // entered state sm
      } else { // left state sm

      }
      break;
    default:
      if (on_off) {
      }
    }
  }
};
