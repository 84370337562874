import $ from 'jquery';
import Pubsub from 'pubsub';

export default function ExitIntentLayer() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));

  ExitIntentLayer.prototype = {

    run: function () {
      var _this = this;
      if (document.location.search.indexOf("utm_source=newsletter") > 0) {
        _this.setLayerBlocked();
        return;
      }

      $("#page").on("mouseleave", function (event) {
        var tolerance = 5;
        var left_exit = (event.clientX <= 0);
        var top_exit = (event.clientY <= 0);
        var right_exit = (window.innerWidth - event.clientX < tolerance);
        var bottom_exit = (window.innerHeight - event.clientY < tolerance);
        var is_element = (event.target.className !== undefined);
        if ( (left_exit || top_exit || right_exit || bottom_exit ) && is_element) {
          _this.showExitIntentLayer();
        }
      });

      $(document).off("click", "#js-exitintent-subscribe").on("click", "#js-exitintent-subscribe", (function (e) {
        const email_address = $('#emailnewsletter').val();
        var salutationcode = $("input[name='salutationcode']:checked").val();
        var firstname = $('#firstname').val();
        var lastname = $('#lastname').val();

        var target = "/ajax/account/newsletter/layer/";

        var data = {
          email: email_address,
          firstname: firstname,
          lastname: lastname,
          salutation: salutationcode,
          subscriptionSource: "ExitIntentLayer",
          subscribe: true,
          specialCategoryRegistration: false
        };
        if (lastname.length < 2) {
          data.salutationcode = "5";
        }
        if (_this.validateEmail(email_address)) {
          var successful = 0;

          $.ajax({
            type: 'POST',
            url: target,
            data: JSON.stringify(data),
            contentType : 'application/json',
            success: function () {
              successful=1;
              $('#first_dialog').hide();
              $('#registration_done').show();
              var trackingData = {
                'eventCategory' : "ClickEvent",
                'eventAction' : "Layer",
                'eventLabel' : "Exit-Intent-Layer-Submit"
              }
              Pubsub.publish('tracking.layer.trackingEvent',trackingData);
            },
            fail: function (error) {
              console.error(error);
            }
          });
        } else {
          $("#errormsg_exitintent").show();
        }
      }));
    },
    validateEmail: function(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    otherLayersHavePriority() {
      var _this = this;
      var content_general = $("#generic-modal").html();
      var content_themodal = $("#the-modal").html();
      var isConsentCookie =  document.cookie.match(new RegExp('ConsentCookie'));
      if ( (isConsentCookie == null) || (content_general.length > 10) || (content_themodal.length > 10)) {
        return true;
      }
      return false;
    },
    showExitIntentLayer:  function() {
      var _this = this;

      if (!_this.isLayerBlocked()) {
        if (_this.otherLayersHavePriority()) {
          return;
        }

        $.get(cfg.exitIntentLayerModalUrl, function (data) {
          if (data != null && (data.markup != null) && (data.markup.length > 16)) {
            $('#generic-modal').html(data.markup);
            $('#generic-modal').modal('show');
            var trackingData = {
              'eventCategory': "ViewEvent",
              'eventAction': "Layer",
              'eventLabel': "Exit-Intent-Layer"
            }
            Pubsub.publish('tracking.layer.trackingEvent', trackingData);
          }
          _this.setLayerBlocked();

        }).fail(function (jqXhr, error) {
          if (window.console) console.log("exitintent-layer error!", error);
        });
      }
    },
    matchesBlockURL: function() {
      const url = window.location.pathname;
      const blockPatterns = [
        /\/checkout\//,
        /\/login\//,
        /\/register\//,
        /\/account\/newsletter\//,
        /^\/$/  // Homepage
      ];
      for (const regex of blockPatterns) {
        if (regex.test(url)) {
          return true;
        }
      }
      return false;
    },
    isLayerBlocked: function() {
      var blockedAfterSingleOccurence = (sessionStorage.getItem("OnlyOneExitIntentLayerPerSession") != null);
      var blockedByURL = this.matchesBlockURL();

      return blockedByURL || blockedAfterSingleOccurence;
    },
    setLayerBlocked: function() {
      sessionStorage.setItem("OnlyOneExitIntentLayerPerSession",1);
      $("#page").off("mouseleave");
    }
  }
  ExitIntentLayer.prototype.run();
};
