import $ from 'jquery';
import Pubsub from 'pubsub';

export default function AcceptCookies() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

AcceptCookies.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var accepteCookies = (navigator.cookieEnabled) ? true : false;


    if (accepteCookies) {
      $(document).off("click", "#js-open-cookie-layer-1").on("click", "#js-open-cookie-layer-1", (function (e) {
        _this.openSecondLayer("js-open-cookie-layer-1");
      }));
      $(document).off("click", "#js-open-cookie-layer-2").on("click", "#js-open-cookie-layer-2", (function (e) {
        _this.openSecondLayer("js-open-cookie-layer-2");
      }));

      $(document).off("click", "#js-accept-cookies-1").on("click", "#js-accept-cookies-1", (function (e) {
        _this.acceptAllCookies("ConsentLayer-1-accept-all-cookies");
      }));
      $(document).off("click", "#js-accept-cookies-2").on("click", "#js-accept-cookies-2", (function (e) {
        _this.acceptAllCookies("ConsentLayer-2-accept-all-cookies");
      }));

      $(document).off("click", "#reject-all-cookies-1").on("click", "#reject-all-cookies-1", (function (e) {
        _this.rejectAllCookies("ConsentLayer-reject-all-cookies");
      }));
      $(document).off("click", "#reject-all-cookies-2").on("click", "#reject-all-cookies-2", (function (e) {
        _this.rejectAllCookies("ConsentLayer-reject-all-cookies");
      }));

      $(document).off("click", "#consent_confirm").on("click", "#consent_confirm", (function (e) {
        _this.confirm();
        $(".cookie-layer").hide();
        $("#the-modal").empty();
        _this.releaseSpecialCategoryPopup();
      }));
    }

    $(document).off("click",".js-toggle-consentdescription").on("click",".js-toggle-consentdescription", (function (e) {
      var field = $(this).attr("data-field");
      var target = "#"+field;
      if ($(target).is(":visible")) {
        $(target).hide();
      }  else {
        $(target).show();
      }
      return false;
    }));

    if (_this.getCookie("ConsentCookie") != null) {
      $(".cookie-layer").hide();
    } else {
      var trackingData = {
        'eventCategory' : "ViewEvent",
        'eventAction' : "Layer",
        'eventLabel' : "ConsentLayer-1"
      }
      $(".cookie-layer").show();
      Pubsub.publish('tracking.layer.trackingEvent',trackingData);
    }

    if (sessionStorage.getItem('first_referrer') == null) {
      sessionStorage.setItem('first_referrer', document.referrer);
    }
  },
  openSecondLayer : function (target) {
    var _this = this;
    $(".cookie-layer").fadeOut('fast');
    var trackingData = {
      'eventCategory' : "ClickEvent",
      'eventAction' : "Layer",
      'eventLabel' : target
    }
    Pubsub.publish('tracking.layer.trackingEvent',trackingData);
    _this.showConsentLayer();
    return false;
  },
  acceptAllCookies : function (target) {
    var _this = this;
    _this.setCookieFullConsent();
    _this.closeAndTrackConsentLayer(target);
    return false;
  },
  rejectAllCookies : function (target) {
    var _this = this;
    _this.triggerCookie("opt_marketing=0|opt_personalization=0|opt_statistics=0",true);
    _this.closeAndTrackConsentLayer(target);
    _this.manageRejectionStatistics();
    _this.reloadAfterConsent();
    return false;
  },
  closeAndTrackConsentLayer : function (target) {
    var _this = this;
    $(".cookie-layer").fadeOut('fast');
    $('#the-modal').modal('hide');
    $("#the-modal").empty();
    _this.releaseSpecialCategoryPopup();
    var trackingData = {
      'eventCategory' : "ClickEvent",
      'eventAction' : "Layer",
      'eventLabel' : target
    }
    Pubsub.publish('tracking.layer.trackingEvent',trackingData);
    return false;
  },
  releaseSpecialCategoryPopup : function () {
    $('#the-modal').modal('hide');
    $("#the-modal").empty();
    if (!sessionStorage.getItem('showSpecialPopupAfterConsent')) {
      sessionStorage.setItem('showSpecialPopupAfterConsent', 'true');
      $.get("/specialCategory/modal/release/");
    }
  },
  run: function () {
    var _this = this;
    _this.showConsentLayer();
  },
  showConsentLayer: function () {
    var _this = this;
    $.get(cfg.consentLayerModalUrl, function(data) {
      $('#the-modal').html(data.markup);
      var popupContent = JSON.stringify(data);
      if (popupContent.indexOf("layer_popup") > -1) {
        _this.loadConsent();
        $('#the-modal').modal('show');
      }
    }).fail(function(jqXhr, error) {
      if (window.console) console.log("consent overlay error!", error);
    });
  },
  loadConsent: function () {
    var _this = this;
    if (_this.getCookie("ConsentCookie") == null) {
      _this.setCookieDefaultConsent(false);
    }
    _this.setConsentOption("opt_personalization");
    _this.setConsentOption("opt_marketing");
    _this.setConsentOption("opt_statistics");
  },
  setConsentOption: function( groupName ) {
    var _this = this;
    var cookieValue = _this.getCookie("ConsentCookie");
    var choosed = (cookieValue.includes(groupName+"=1")) ? true : false;
    $("input#"+groupName).attr("checked",choosed);
  },
  confirm: function() {
    var _this = this;

    const isChoiceStatistics = $("#opt_statistics").is(":checked") ? "1" : "0";
    const isChoicePersonalization = $("#opt_personalization").is(":checked") ? "1" : "0";
    const isChoiceMarketing  = $("#opt_marketing").is(":checked") ? "1" : "0";
    var newConsent= "opt_marketing=" + isChoiceMarketing+ "|opt_personalization=" + isChoicePersonalization
      +  "|opt_statistics=" + isChoiceStatistics;
    _this.triggerCookie(newConsent,true);
    if (!isChoiceStatistics) {
      _this.manageRejectionStatistics();
    }
    _this.reloadAfterConsent();
  },
  getCookie: function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  setCookie: function(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      var expires = "; expires=" + date.toGMTString();
    }
    else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  },
  setCookieFullConsent: function() {
    var _this = this;
    _this.triggerCookie("opt_marketing=1|opt_personalization=1|opt_statistics=1",true);
    _this.reloadAfterConsent();
  },
  setCookieDefaultConsent: function(confirmedByUser) {
    var _this = this;
    _this.triggerCookie( "opt_marketing=0|opt_personalization=0|opt_statistics=0",confirmedByUser);
  },
  triggerCookie: function(consentPart,confirmedByUser) {
    var _this = this;
    _this.setCookie("ConsentCookie" ,
      consentPart + ((confirmedByUser) ? "|confirmedAt=" + (new Date()).toISOString() : ""), 730);
  },
  reloadAfterConsent: function() {
    var currentURL = window.location.href;
    var theReferrer = sessionStorage.getItem('first_referrer');
    if (theReferrer.length > 0) {
      const searchParams = new URLSearchParams(window.location.search);
      const delimiter = (searchParams.keys().length > 0) ? "&" : "?";
      currentURL = currentURL + delimiter + "refURL=" + encodeURIComponent(theReferrer);
    }
    window.location.href = currentURL;
  },
  manageRejectionStatistics: function() {
    var alreadyFlagged = sessionStorage.getItem('no_consent_statistics');
    if (alreadyFlagged) {
      return;
    }
    sessionStorage.setItem('no_consent_statistics',true);
    var trackingData = {
      'eventCategory' : "ClickEvent",
      'eventAction' : "opt_statistics",
      'eventLabel' : "rejected"
    }
    Pubsub.publish('tracking.layer.trackingEvent',trackingData);
  }
};
