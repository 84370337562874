import $ from 'jquery';
import Pubsub from 'pubsub';
import Html5Validation from '../utils/html5validation';

export default function Content() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

Content.prototype = {
  attach: function(msg, element) {

    var html5validation = new Html5Validation();
    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

  }
};
