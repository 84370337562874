import $ from 'jquery';

function updateSeoRelTags(){
    var newRelPrev= $('.product-list-part').data('prev');
    var newRelNext= $('.product-list-part').data('next');
    var isNewRelPrevFirstPage = newRelPrev.indexOf("p=0") >= 0;

    // Tag is currently shown in HEAD
    var currPrev= document.querySelector('link[rel="prev"]');
    var currNext = document.querySelector('link[rel="next"]');

    if (newRelPrev === ''){
      $('head link[rel="prev"]').remove();
    } else if(currPrev === null && isNewRelPrevFirstPage){
      var posNewPrev = newRelPrev.lastIndexOf('/');
      $('head').append('<link rel="prev" href="' + newRelPrev.substr(0, posNewPrev + 1) + '">');
    } else if(currPrev === null && !isNewRelPrevFirstPage){
      $('head').append('<link rel="prev" href="' + newRelPrev + '">');
    } else {
      currPrev.setAttribute("href", newRelPrev);
    }

    if(newRelNext === ''){
      $('head link[rel="next"]').remove();
    } else if (currNext === null){
      $('head').append('<link rel="next" href="' + newRelNext + '">');
    } else{
      currNext.setAttribute("href", newRelNext);
    }
  }

  function updateMetaRobotsCategory(){
    var canonical = $('.product-list-part').data('canonical');
    if (canonical !== '') {
      var newUrl = new URL(canonical);
      var url_params = newUrl.searchParams;
      var hasUrlPageParams = url_params.has("p");
      if (hasUrlPageParams) {
        $('head meta[name="robots"]').remove();
        $('head').append('<meta name="robots" content="noindex,follow">');
      } else {
        $('head meta[name="robots"]').remove();
        $('head').append('<meta name="robots" content="index,follow">');
      }
    }
  }

function updateCanonicalUrl(){
  var canonical = $('.product-list-part').data('canonical');
  var isFirstPage = canonical.indexOf("p=") <= 0;
  if (!isFirstPage) {
    $('head link[rel="canonical"]').remove();
    $('head').append('<link rel="canonical" href="' + canonical + '">');
  } else {
    $('head link[rel="canonical"]').remove();
  }
}

export {updateSeoRelTags, updateMetaRobotsCategory, updateCanonicalUrl};
