import $ from 'jquery';
import Pubsub from 'pubsub';
import owl from 'owlcarousel';

export default function BrandsTeaser() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

BrandsTeaser.prototype = {
  attach: function(msg, element) {
    $(".owl-carousel").owlCarousel({
      items: 6, // 10 items above 1000px browser width
      itemsDesktop: [1200, 5], // 5 items between 1000px and 901px
      itemsDesktopSmall: [1025, 4], // betweem 900px and 601px
      itemsTablet: [768, 3], // 2 items between 600 and 0
      itemsMobile: [480, 2], // itemsMobile disabled - inherit from
      // itemsTablet option
      navigation: false,
      slideSpeed: 2000,
      rewindSpeed: 500,
      autoPlay: 2000,
      stopOnHover: true,
      scrollPerPage: true
    });
  }
};
