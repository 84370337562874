import $ from 'jquery';
import Pubsub from 'pubsub';

export default function GenericFrontendModel() {
  Pubsub.subscribe('generic.model.handler', $.proxy(this.handler, this));
}

GenericFrontendModel.prototype = {

  handler: function(msg, genericFrontendModel) {
    var _this = this;

    var data = genericFrontendModel.data;
    if (data.hasOwnProperty('contact')) {
      _this.contact_form(data.contact.contactModel);
    }
  },

  contact_form: function(model) {
    $('#contactForm').find('#salutation').val(model.salutation);
    $('#contactForm').find('#firstName').val(model.firstName);
    $('#contactForm').find('#lastName').val(model.lastName);
    $('#contactForm').find('#apartment').val(model.apartment);
    $('#contactForm').find('#deliveryPoint').val(model.deliveryPoint);
    $('#contactForm').find('#street').val(model.street);
    $('#contactForm').find('#addressAddition').val(model.addressAddition);
    $('#contactForm').find('#zipCode').val(model.zipCode);
    $('#contactForm').find('#city').val(model.city);
    $('#contactForm').find('#phone').val(model.phone);
    $('#contactForm').find('#mobilePhone').val(model.mobilePhone);
    $('#contactForm').find('#email').val(model.email);
  },

};
