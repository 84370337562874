import $ from 'jquery';
import Pubsub from 'pubsub';
import Html5Validation from '../utils/html5validation';
import {Validator} from '../utils/forms';
import config from '../config/config';
import Loading from '../components/loading';
import * as Urls from '../utils/urls';
import '../utils/form_elements/plusminus';
import 'bootstrap';

export default function CartChange() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));

  this.loading = new Loading();
}

CartChange.prototype = {

  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    $(".count-element", element).plusminus();

    // click edit button
    $(".actions .edit a, .js-edit a").off("click").on("click", function(e) {
      e.preventDefault();

      var $this = $(this);

      if ($this.hasClass("loading")) return;

      var cart_product = $this.closest(".cart-product");

      var cart_edit = cart_product.find(".cart-edit");
      if (cart_edit.length > 0) {
        cart_edit.collapse("hide");
        return;
      }

      $(".cart-edit").not(cart_edit).collapse("hide");

      var params = {
        itemId: $(this).attr("data-itemId"),
        catalogIndex: $(this).attr("data-catalogIndex"),
        index: $(this).attr("data-index"),
        free: $(this).attr("data-free"),
        personalTextHash: $(this).attr("data-phash")
      }
      var query = $.param(params);

      _this.loading.load();

      // load edit form
      $.get(Urls.make(config.basketEditPath, query), function(data) {
        var cart_edit = $(data);
        cart_product.find("div.clearboth").after(cart_edit);

        if($('.cart-product .lastPos .edit, .cart-product .js-edit.lastPos').length === 1){
          $('.cart-edit .input-group-btn.minus a').attr('data-cerberus','basket-modification-button-minus');
          $('.cart-edit .input-group-btn.plus a').attr('data-cerberus','basket-modification-button-plus');
          $('.cart-edit .input-group input[id="order-count"]').attr('data-cerberus','basket-order-count-field');
          $('.cart-edit .action-btn.edit').attr('data-cerberus','basket-modification-validation-button');
        }

        _this.loading.stop();

        if($('.cart-edit .single-size input:checked')){
          $('.cart-edit .single-size input:checked').parent().addClass("active");
        }

        if($('.cart-edit .single-size input:disabled')){
          $('.cart-edit .single-size input:disabled').parent().addClass("disabled");
        }

        //Attach event handlers
        Pubsub.publish("core.attach", cart_edit);

        cart_edit.collapse('show').on("shown.bs.collapse", function() {
        });
      }).fail(function(jqXhr, e) {
        log(e);
      });
    });

    //
    if ($(element).is(".cart-edit")) {
      var cart_edit = element;

      cart_edit.bind("hidden.bs.collapse", function() {
        $(this).remove();
      });
      cart_edit.collapse({
        toggle: false
      });

      $("[data-update]", cart_edit).off("change").on("change", function(e) {
        _this.loading.load();
        var $element = $(e.target);
        $.get(Urls.make(config.basketEditPath, $("form", cart_edit).serialize()), function(data) {
          var $data = $(data);
          cart_edit.replaceWith($data);
          $("label[for='" + $element.attr('id') + "']").addClass('active');
          if($('.cart-edit .single-size input:disabled')){
            $('.cart-edit .single-size input:disabled').parent().addClass("disabled");
          }
          _this.loading.stop();

          //Attach event handlers
          Pubsub.publish("core.attach", $data);
          $data.addClass("in");
        }).fail(function(jqXhr, e) {
          log(e);
        });
      });


      //Submit edit form
      $("button.edit", element).off("click").on("click", function(e) {
        var form = $(this).closest("form");
        var validator = new Validator(form);
        e.preventDefault();

        if (!validator.validate()) {
          return;
        }
        $('.js-basket-loader').addClass('loader');
        var _this = $(this);
        $.post(form.attr("action"), form.serialize(), function(data) {
          if (data.success) {
            $(".cart-edit").collapse("hide");
            var product = $(".cart-edit").closest(".cart-product");
            window.location.reload(true);
          }else{
            $(_this).parents('form').find('.js-ptext-error').show();
            $('.js-input-ptext').each(function(i, obj){
              var _this = this;
              if($(_this).val().length > $(_this).data('maxlength')){
                $(_this).addClass('has-error');
                $(_this).parents('form').find('.js-ptext-error').css("padding-bottom","15px");
              }
            });
          }
        }).fail(function(jqXhr, e) {
          log(e);
        }).always(function(){
          $('.js-basket-loader').removeClass('loader');
        });
      });
    }

    // submit edit position amount
    $('.js-change-amount select').off('change').on('change', function(e) {
      e.preventDefault();
      var _this = $(this);
      var amount_change = _this.closest(".js-change-amount");

      var params = {
        itemId: amount_change.attr("data-itemId"),
        newItemId: amount_change.attr("data-itemId"),
        catalogIndex: amount_change.attr("data-catalogIndex"),
        amount: _this.val(),
        index: amount_change.attr("data-index"),
        free: amount_change.attr("data-free"),
        personalTextHash: amount_change.attr("data-phash"),
        size: amount_change.attr("data-size")
      }
      var query = $.param(params);
      $('.js-basket-loader').addClass('loader');

      $.post(Urls.make(config.basketEditSubmitPath, query), function (response) {
        if(response.success) {
          window.location.reload();
        }
      }).fail(function (response) {
        window.console && console.log(response);
      })
    });

    $(".js-close-variation").off('click').on('click', function() {
      var $this = $(this);
      var cart_product = $this.closest(".cart-product");
      var cart_edit = cart_product.find(".cart-edit");

      if (cart_edit.length > 0) {
        cart_edit.collapse("hide");
        cart_product.find(".js-edit").removeClass("hidden");
        return;
      }
    });

  }

};
