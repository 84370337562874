import $ from 'jquery';
import Pubsub from 'pubsub';
import Html5Validation from '../utils/html5validation';
import scrollToElement from '../utils/scrollTo';

export default function EmailSubmit() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

EmailSubmit.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    html5validation.addValidationHandler();

    $(".js-contact-form").off("submit").on("submit", function(e) {
      e.preventDefault();
      var errors = html5validation.validateOnAjaxSubmit($(this));
      if (!errors){
        _this.emailSubmit();
      }
      else {
        scrollToElement($('.has-error:first'), 500);
      }
    });

  },
  emailSubmit: function() {
    var form = $(".js-contact-form");

    $.post(form.attr('action'), form.serialize())
    .done(function(data){
      if(data.resultStatus == "success") {
        $("html, body").animate({
          scrollTop: 0
        }, 500);
        form.slideUp('slow').fadeOut(1000, function(){
          $(".js-success-message").html(data.resultMessage).show().fadeIn(1000, function(){
            form.find('input[type=text], input[type=email], textarea, select').val('').removeAttr('selected');
            form.hide();
            if($(".js-error-message").is(":visible")) {
              $(".js-error-message").addClass("hidden");
            }
           })
        });
      } else {
        $("html, body").animate({
          scrollTop: 0
        }, 500);
        $(".js-error-message").html(data.resultMessage).show().fadeIn(1000);
      }
      if(data.hasErrors){
        $.each(data.errors, function(i, value){
          $(".js-error-message").html(value.defaultMessage).show().fadeIn(1000);
        });
      }
    })
    .fail(function(data){
      if(data.hasErrors) {
        $('.js-contact-error').show();
        $.each(data.errors, function(i, value){
          $('.js-contact-error').append('<div class="msg">' + value.defaultMessage +'</div>');
        });
      }
    });
  }
};
