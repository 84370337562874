/**
 * This script implements a fully client side form validation. It registers a
 * focus listener to each form input element ("input", "select") that uses the
 * CSS class "form-control", managing the live error feedback to the user. On
 * initialization it sets a placeholder value for each input not already having
 * one with the value of its label.
 * <p>
 * Each input's validation is configured by its attribute "data-restriction".
 * The following comma separated validation informations can by set:
 * <ul>
 * <li>"type" (defaults to "text")
 * <ul>
 * <li>"text"</li>
 * <li>"email" input is validated to an email address</li>
 * <li>"phone" input is validated to a phone number</li>
 * <li>"choice" input is validated to a selected value from its "option"s list.
 * The first entry is used as the placeholder</li>
 * </ul>
 * </li>
 * <li>"min" (defaults to "1"). To declare an input as optional, specify a min
 * of value 0.</li>
 * <li>"max" (defaults to Number.MAX_VALUE)</li>
 * </ul>
 * </p>
 * <p>
 * The developer is responsible for keeping the client side validation at least
 * as restrictive as the corresponding server side validation. The latter one
 * results just in true or false.
 * </p>
 */

import $ from 'jquery';
import Pubsub from 'pubsub';
import Html5Validation from "../utils/html5validation";

Pubsub.subscribe('core.attach', init);

function init() {
  $('form.shop-form').off('submit').on('submit', function() {
    validateForm(this.id);
    return false;
  });

  $('form.shop-form .error-tip').each(function() {
    var $errorTip = $(this);

    var $formControl = $('#' + $errorTip.attr('id').substring(0, $errorTip.attr('id').lastIndexOf('.')));

    var position = $formControl.position();
    position.top += $formControl.outerHeight();
    position.top += parseInt($formControl.css('marginTop'));
    $errorTip.css(position);
  });

  $('form.shop-form .form-control[type]:not([type=select]):not([placeholder])').each(function() {
    var $label = $('label[for=' + this.id + ']');
    $(this).attr('placeholder', $label.text().trim());
  });

  $('form.shop-form .form-control[data-restriction]').each(function() {
    var $formControl = $(this);
    installValidator($formControl);
  });

  $('form.shop-form .form-control').first().focus();

  var html5validation = new Html5Validation();

  html5validation.addValidationHandler();
  html5validation.validateOnSubmit();
}

function hideErrorTip(id) {
  $('#' + id + '\\.errors').remove();
  $('#' + id).removeClass('error');
}

function validateForm(formID) {
  var $form = $('#' + formID);
  $.ajax({
    type: $form.attr('method'),
    url: $form.attr('action') + 'form/',
    data: $form.serialize(),
    success: function(response) {
      if (response.errors) {
        $('form.shop-form .form-control').each(function() {
          var $formControl = $(this);
          $formControl.prop('validator').validate();
        });
        $('form.shop-form .form-control.error').first().focus();
        return;
      }

      actuallySubmit(formID);
    },
    error: function(jqXhr, status, e) {
      alert('Error: ' + e);
    // any visual response
    }
  });
}

function actuallySubmit(formID) {
  var $form = $('#' + formID);
  $.ajax({
    type: $form.attr('method'),
    url: $form.attr('action'),
    data: $form.serialize(),
    success: function(response) {
      if (response.errors) {
        $('form.shop-form .form-control').each(function() {
          var $formControl = $(this);
          $formControl.prop('validator').validate();
        });
        $('form.shop-form .form-control.error').first().focus();
        return false;
      }

      if (response.resultRedirect != undefined) {
        document.location = response.resultRedirect;
        return false;
      }

      if (response.resultStatus == 'success') {
        $('form.shop-form').hide();
        $('.js-remove-on-form-success').hide();
      }

      var $resultMessage = $('#shopform-alert');
      if (!$resultMessage.length) {
        $resultMessage = $('<div>').attr('id', 'shopform-alert').addClass('xrow alert alert-danger').insertAfter($form);
      }
      $resultMessage.html(response.resultMessage);
      $resultMessage.find('.alert').removeClass('alert-warning').removeClass('alert-danger');
      $resultMessage.find('.alert').addClass('alert-' + (response.resultStatus == 'success' ? 'warning' : 'danger'));

      return false;
    },
    error: function(jqXhr, status, e) {
      alert('Error: ' + e);
    // any visual response
    }
  });
}
