import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../components/loading';
import 'jquerybase64';
import {updateCanonicalUrl, updateMetaRobotsCategory, updateSeoRelTags} from '../components/seotags';

var defaults = {
  container: 'body',
  contentSelector: ''
};

$.fn.pagination = function(options) {
  var options = Object.assign(defaults, options);
  var matched = $(this);

  matched.each(function() {
    $(this).data("Pagination", new Pagination($(this), options.container, matched));
  });

  return matched;
};

export default function Pagination($el, $container, matched) {
  this.loading = new Loading();
  this.$el = $el;
  var _this = this;


  $el.find("a").off("click").on("click", function(e) {
    e.preventDefault();

    _this.loading.load();

    $.get($.base64.decode($(this).data("ajax-href")), function(data) {

      $('html').animate({
        scrollTop: $container.offset().top}, 400, function() {
        var $data = $(data);
        Pubsub.publish("core.preattach", $data);
        $container.replaceWith($data);
        updateSeoRelTags();
        updateMetaRobotsCategory();
        updateCanonicalUrl();
        Pubsub.publish("tracking.pageViewAjaxEvent", null);
        Pubsub.publish("core.attach", $data);
        _this.loading.stop();
      });
    }).fail(function(error) {
      if (window.console) console.log(error);
    });
  });
}

Pagination.prototype = {
  activePage: function() {
    return this.$el.find(".active a").attr("data-page");
  }
};
