import $ from "jquery";
import PubSub from "pubsub";

export default class Loading {

  constructor(options = {}) {
    var defaults = {
      el: "body"
    };
    this.options = Object.assign(defaults, options);
  }

  load() {
    if ($(this.options.el + " > .loading").length == 0) {
      $(this.options.el)
        .prepend('<div class="loading"><div class="content loader"><div class="inner">&nbsp;</div></div></div>');
    }
  }

  stop() {
    $(this.options.el + "> .loading").remove();
  }
}
