import $ from 'jquery';
import Pubsub from 'pubsub';
import config from "../config/config";

export default function Suggestions() {
    Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

Suggestions.prototype = {
    attach: function (msg, element) {
        let $flyout = $('.js-search-flyout, .js-search-flyout-no-results');
        let $headerflyout = $('.js-search-flyout');
        let $noresultsflyout = $('.js-search-flyout-no-results');

        $(document).click(function (e) {
            if (!$flyout.is(e.target) && $flyout.has(e.target).length === 0) {
                $flyout.html('');
            }
        });

        $flyout.on("click", ".js-show-all-search-results", function (e) {
            e.preventDefault();
            if ($(this).closest('.js-search-flyout').length > 0) {
                $('header #search_id_form').submit();
            } else {
                $('.no-search-result #search_id_form').submit();
            }
        });

        $flyout.on("click", ".js-close-search-results", function (e) {
            e.preventDefault();
            $flyout.html('');
        });

        $flyout.on("click", ".js-suggestion-value", function (e) {
            e.preventDefault();
            if (!$(this).data("link") || $(this).data("link") === "") {
                if (!$(this).data("categoryencodedpath") || $(this).data("categoryencodedpath") === "") {
                    $('input[name="qc"]').remove();
                } else {
                    $('input[name="qc"]').val($(this).data("categoryencodedpath"));
                }
                $('input[name="q"]').val($(this).data("searchvalue"))
                $('#search_id_form').submit();
            } else {
                window.location.href = $(this).data("link");
            }
        });

        $('.js-suggestions-input').off("keyup").on('keyup', function (e) {
            e.preventDefault();
            if (!e.target.value || e.target.value.length < 2) {
                $flyout.html('');
                return;
            }
            let _this = $(this);
            $.post(config.suggestions + e.target.value)
                .done(function (result) {
                    if (result.success) {
                        var newContent = $(result.result);
                        if (_this.is('.js-suggestions-no-results')) {
                            $noresultsflyout.html(newContent);
                            $noresultsflyout.show();
                        } else {
                            $headerflyout.html(newContent);
                            $headerflyout.show();
                        }
                    }
                });
        });


    }
};