import $ from 'jquery';
import Pubsub from 'pubsub';
import 'swipe';
import config from '../../config/config';
import '../../utils/slidercontrols';
import '../../utils/sliderknobs';
import '../../utils/sliderlazyload';

export default function RecommendationSwipe() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
}

RecommendationSwipe.prototype = {
  attach: function(msg, element) {},

  responsive: function(msg, on_off) {

    var recoSwipeItem = $(".recommendation .swipe .product-grid-item");
    var desktopNumber = $("[data-custom-switch-number-desktop]").data("custom-switch-number-desktop");
    var mobileNumber = $("[data-custom-switch-number-mobile]").data("custom-switch-number-mobile");
    var type = msg === 'res.xs' ? "mobile" : "desktop";

    if(type === 'mobile') {
      recoSwipeItem.removeClass("xcol-2-mobile xcol-3-mobile xcol-4-mobile xcol-6-mobile xcol-12-mobile");
    } else {
      recoSwipeItem.removeClass("xcol-2-desktop xcol-3-desktop xcol-4-desktop xcol-6-desktop xcol-12-desktop");
    }

    if(mobileNumber !== undefined && msg === 'res.xs') {
      if (on_off) { // entered state xs
        this.initializeRecoSwipe(mobileNumber, type);
      }
    } else if(desktopNumber !== undefined && msg !== 'res.xs') {
      if (on_off) {
        this.initializeRecoSwipe(desktopNumber, type);
      }
    } else {
      switch (msg) {
      case 'res.xs':
        if (on_off) { // entered state xs
          this.initializeRecoSwipe(config.swipePairXs, type);
        }
        break;
      case 'res.sm':
        if (on_off) { // entered state sm
          this.initializeRecoSwipe(config.swipePairSm, type);
        }
        break;
      default:
        if (on_off) {
          this.initializeRecoSwipe(config.swipePairDefault, type);
        }
      }
    }
  },
  initializeRecoSwipe: function(pair, type) {
    var recoSwipe = $(".recommendation .swipe");
    this.removeSwipes();
    recoSwipe.Swipe({
      pair: pair,
      continuous: false
    }).controls().lazy();
    this.addSizeClasses(pair, type);
  },
  addSizeClasses: function(colNumber, type){
    const colCase = colNumber.toString();
    const recoSwipeItem = $(".recommendation .swipe .product-grid-item");
    switch (colCase) {
      case "1":
        recoSwipeItem.addClass("xcol-12-" + type);
        break;
      case "2":
        recoSwipeItem.addClass("xcol-6-" + type);
        break;
      case "3":
        recoSwipeItem.addClass("xcol-4-" + type);
        break;
      case "4":
        recoSwipeItem.addClass("xcol-3-" + type);
        break;
      case "6":
        recoSwipeItem.addClass("xcol-2-" + type);
        break;
      default:
        recoSwipeItem.addClass("xcol-3-" + type);
    }
    Pubsub.publish("core.attach", $(".recommendation"));
  },
  removeSwipes: function() {
    $(".recommendation .swipe").each(function() {
      if ($(this).data("Swipe")) $(this).data("Swipe").kill();
    });
  }
};
