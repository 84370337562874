import $ from 'jquery';
import Pubsub from 'pubsub';
import 'slimScroll';

export default function ServiceTabScroll() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

ServiceTabScroll.prototype = {
  attach: function(msg, element) {
    $(".serviceTabScrollBar").slimScroll({
      height: "auto",
      size: '10px'
    });
  }
};
