export function parseMonetaryAmount(s) {
  var result = Number(s.replace(/\./, "").replace(/,/, ".").replace(/\s+€/, ""));
  result = Math.round(result * 100);
  return result;
}

export function formatMonetaryAmount(ma, symbol) {
  if (!symbol)
    symbol = "€";
  if (ma < 0) return "-" + formatMonetaryAmount(-ma);

  var result = "" + Math.floor(ma / 100.0) + ",";
  var cent = ma % 100;
  if (cent < 10)
    result += "0";
  result += cent;
  result += "\xA0" + symbol;
  return result;
}

export function formatPercentage(num) {
  var result = "" + Math.floor(num) + ",";
  var frac = Math.floor(num * 100);
  if (frac < 10)
    result += "0";
  result += frac;
  result += "\xA0%";
  return result;
}
