import $ from "jquery";
import Pubsub from "pubsub";

export default function Offcanvas(xssidebarclass) {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  var xssidebarclass = xssidebarclass ? xssidebarclass : "product-filter-list";

  this.sidebarselector = ".sidebar-offcanvas." + xssidebarclass;
  this.xssidebarclass = xssidebarclass + " xs-sidebar-offcanvas";
  this.xssidebarselector = ".xs-sidebar-offcanvas." + xssidebarclass;
  this.xssidebarselectorcontainer = this.xssidebarselector + " .xcontainer";
}

Offcanvas.prototype = {
  attach: function(msg, element) {
    if (this.isxs && !$(this.sidebarselector).is(':empty')) this.switchIt(this.isxs);
  },
  active: function() {
    return $("body").hasClass("offcanvas");
  },
  activatePage: function() {
    var _this = this;
    $("#page").off("click").on("click", function(e) {
      e.preventDefault();
      _this.hide();
      $(this).off("click");
    });
  },
  selectSidebar: function() {
    $(".xs-sidebar-offcanvas").hide();
    $(this.xssidebarselector).show();
  },
  show: function() {
    var _this = this;
    this.selectSidebar();

    $("body").addClass("offcanvas");
    setTimeout(function() {
      $("html, body").animate({
        scrollTop: 0
      }, 200);
      _this.activatePage();
    }, 10);

  },
  deactivatePage: function() {
    $("#page").off("click");
  },
  hide: function() {
    $("body").removeClass("offcanvas");
    this.deactivatePage();
  },
  toggle: function() {
    if ($("body").hasClass("offcanvas")) {
      this.hide();
    }
    else this.show();
  },
  switchIt: function(on_off, replace) {
    if (!replace && on_off == this.isxs) return;

    this.isxs = on_off;

    if (on_off) { // enter sidebar
      if ($(this.xssidebarselector).length == 0) {
        $("body").prepend('<div class="x-style ' + this.xssidebarclass + '"><div class="xcontainer no-spacing shop-navigation"></div></div>');
      }
      var markup = $(this.sidebarselector).html();
      if (markup) {
        $(this.xssidebarselectorcontainer).html(markup);
        $(this.sidebarselector).empty();
        Pubsub.publish("core.attach", $(this.xssidebarselector));
      }
    } else { // exit sidebar
      var markup = $(this.xssidebarselectorcontainer).html();
      if (markup) {
        $(this.sidebarselector).html(markup);
        $(this.xssidebarselector).remove();
        Pubsub.publish("core.attach", $(this.sidebarselector));
        this.hide();
      }
    }
  }
};
