/**
* jQuery Plugin for swipejs, adding lazy load to images having the data-src attribute
**/
import $ from 'jquery';

function Lazy($el) {
  this.slider = $el.data("Swipe");
  this.$el = $el;
  this.init();
  this.$el.bind("swipe.kill", $.proxy(this.kill, this));
}

Lazy.prototype = {
  init: function() {
    if (!this.slider) return;

    this.numSlides = this.slider.getNumSlides();
    this.pos = this.slider.getPos();

    this.originalCallback = this.slider.options.callback;
    this.slider.options.callback = $.proxy(this.callback, this);

    this.callback(this.pos);
  },
  kill: function() {
    this.$el.unbind("swipe.kill");
  },
  callback: function(pos) {
    if (this.originalCallback) this.originalCallback(pos);

    if (this.numSlides == 2) {
      this.loadImageAtPos(pos);
      this.loadImageAtPos(pos + 2);
    }
    else this.loadImageAtPos(pos);
  },
  loadImageAtPos: function(pos) {
    var _this = this;
    $(".swipe-wrap > div", this.$el).eq(pos).find("img[data-src]").each(function() {
      $(this).off("load").on("load", function() {
        _this.slideWidth = $(this).width();
        _this.slideHeight = $(this).height();
        $(this).css({
          height: "auto",
          width: "auto"
        }).addClass("loaded");

        $(".swipe-wrap > div img", _this.$el).not(".loaded").css({
          width: _this.slideWidth + "px",
          height: _this.slideHeight + "px"
        });
      });


      $(this).attr("src", $(this).attr("data-src"));
    });
  }
};

$.fn.lazy = function() {
  var matched = $(this);

  matched.each(function() {
    new Lazy($(this));
  });
  return matched;
};
