import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';

export default function PayPalPayments() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('payment.paypal', $.proxy(this.attach, this));
}

PayPalPayments.prototype = {

  attach: function (msg, element) {
    var _this = this;
    if (element === document) {
      _this.getPaymentDataAndProceed();
    }
  },

  getPaymentDataAndProceed: function () {
    var _this = this;

    var ajaxUrl = config.payPalPaymentDataUrl;
    $.ajax({
      url: ajaxUrl,
      success: function (data) {
        _this.paypal_data = data.payPalClientData;
        _this.processPayPal();
        $(".paypal-button").children().remove();
      },
      fail: function (error) {
        _this.payPalErrors("err", "technical");
        console.error(error);
      }
    });
  },

  processPayPal: function() {
    var _this = this;

    if (_this.paypal_data.paymentType === 'PAYPAL') {
      _this.payPalCheckoutInit();
    }

  },

  payPalCheckoutInit: function () {
    var _this = this;
    _this.initPaymentAndProceedWithFunction(_this.payPalCheckoutFunction);
  },

  localPaymentInit: function () {
    var _this = this;
    const clientToken = _this.paypal_data.braintreeClientToken;
    // Create a client.
    braintree.client.create({authorization: clientToken}, function (clientErr, clientInstance) {
      if (clientErr) {
        _this.payPalErrors("paynow-err", "technical");
        console.error('Error creating client:', clientErr);
        return;
      }

      braintree.dataCollector.create({
        client: clientInstance,
        paypal: true
      }, function (err, dataCollectorInstance) {
        if (err) {
          // Handle error in creation of data collector
          // Intentionally left blank since fraud protection is optional
          console.error("Error in data collector: " + err)
        }
        _this.deviceData = dataCollectorInstance.deviceData;
      });

      // Create a local payment component.
      braintree.localPayment.create({
        client: clientInstance,
        merchantAccountId: config.payPalMerchantAccountId
      }, function (localPaymentErr, paymentInstance) {
        if (localPaymentErr) {
          _this.payPalErrors("paynow-err", "technical");
          console.error('Error creating local payment:', localPaymentErr);
          return;
        }

        _this.localPaymentInstance = paymentInstance;
      });
    });
  },

  initPaymentAndProceedWithFunction: function (processPaymentTypeFn) {
    var _this = this;
    var ajaxUrl = config.payPalInitPaymentUrl;
    $.ajax({
      url: ajaxUrl,
      method: 'GET',
      success: function () {
        // process dedicated payment type logic
        processPaymentTypeFn(_this);
      },
      fail: function (error) {
        _this.payPalErrors("err", "technical");
        console.error(error);
      }
    });
  },

  payPalCheckoutFunction: function(that) {
    var _this = that;
    const clientToken = _this.paypal_data.braintreeClientToken;
    braintree.client.create({authorization: clientToken}, function (clientErr, clientInstance) {
      if (clientErr) {
        _this.payPalErrors("err", "technical");
        console.error('Error creating client:', clientErr);
        return;
      }

      braintree.dataCollector.create({
        client: clientInstance,
        paypal: true
      }, function (err, dataCollectorInstance) {
        if (err) {
          // Handle error in creation of data collector
          // Intentionally left blank since fraud protection is optional
          console.error("Error in data collector: " + err)
        }
        _this.deviceData = dataCollectorInstance.deviceData;

        braintree.paypalCheckout.create({client: clientInstance}, function (paypalCheckoutErr, paypalCheckoutInstance) {
          if (paypalCheckoutErr) {
            _this.payPalErrors("err", "technical");
            console.error('Error creating PayPal Checkout: ', paypalCheckoutErr);
            return;
          }

          paypal.Button.render({
            env: config.payPalEnv,
            locale: config.payPalLocale,
            buttonStyle: {
              color: 'gold',
              shape: 'rect',
              size: 'responsive',
              label: 'pay',
              locale: 'fr_FR'
            },

            payment: function () {
              return paypalCheckoutInstance.createPayment({
                // http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
                flow: 'checkout',
                amount: _this.paypal_data.totalAmount,
                currency: 'EUR',
                enableShippingAddress: true,
                shippingAddressEditable: false,
                shippingAddressOverride: {
                  recipientName: _this.paypal_data.shippingAddress.firstName + " " + _this.paypal_data.shippingAddress.lastname,
                  line1: _this.paypal_data.shippingAddress.street + " " + _this.paypal_data.shippingAddress.streetNumber,
                  city: _this.paypal_data.shippingAddress.city,
                  countryCode: _this.paypal_data.shippingAddress.country,
                  postalCode: _this.paypal_data.shippingAddress.zipCode
                }
              });
            },

            onAuthorize: function (data, actions) {
              // PayPal Checkout backdrop
              $('.js-backdrop-loader').removeClass('hidden');
              try {
                return paypalCheckoutInstance.tokenizePayment(data, function (error, payload) {
                  if (error !== null) {
                    console.error("PayPal checkout responses with error " + error);
                  }
                  _this.savePaymentAndRedirect(payload.nonce, _this.deviceData);
                });
              } catch (e) {
                console.error("Failed to execute on Authorize for PayPal checkout with error " + e);
              }
              $('.js-backdrop-loader').addClass('hidden');
            },

            onCancel: function (data) {
              _this.payPalErrors("err", "technical");
            },

            onError: function (err) {
              _this.payPalErrors("err", "technical");
              console.error('checkout.js error', err);
            }
          }, '#paypal-button').then(function () {
            // The PayPal button will be rendered in an html element with the id
            // `paypal-button`. This function will be called when the PayPal button
            // is set up and ready to be used.
          });
        })
      });
    });
  },

  savePaymentId: function (paymentId, start) {
    var _this = this;
    var postData = {
      'paymentId': paymentId,
      'paymentNonce': null,
      'deviceData': null
    };

    var ajaxUrl = config.savePayPalDataUrl;
    $.ajax({
      type: 'POST',
      url: ajaxUrl,
      data: JSON.stringify(postData),
      contentType : 'application/json',
      success: function () {
        start();
      },
      fail: function (error) {
        _this.payPalErrors("err", "technical");
        console.error(error);
      }
    });
  },

  savePaymentAndRedirect: function (paymentNonce, deviceData) {
    var _this = this;
    var postData = {
      'paymentId': null,
      'paymentNonce': paymentNonce,
      'deviceData': deviceData
    };

    var ajaxUrl = config.savePayPalDataUrl;
    $.ajax({
      type: 'POST',
      url: ajaxUrl,
      data: JSON.stringify(postData),
      contentType : 'application/json',
      success: function (data) {
        if (data.success) {
          document.getElementById("stepModel").submit();
        } else {
          console.error("Failed to save payment data and redirect to confirmation page! " +
            "Technical error or order has been placed already (paynow). " + data.redirectUrl);
          if (data.redirectUrl) {
            window.location = data.redirectUrl;
          }
          _this.payPalErrors("err", "technical");
        }
      },
      fail: function (error) {
        _this.payPalErrors("err", "technical");
        console.error(error);
      }
    });
  },

  payPalErrors: function (err, type) {
    $(".paypal-js-error-" + type).show();
    $(".panel-default.paypal-" + err).addClass('has-error');
    $(".checkout-content").find(":button").attr("disabled",true);
  },

  paypal_data: {},
  localPaymentInstance: {},
  deviceData: {}
};
