import $ from "jquery";
import Pubsub from "pubsub";
import enquire from "enquire";

import Ads from './views/ads.js';
import Product from './views/product';
import Account from './views/account';
import Checkout from './views/checkout';
import Cart from './views/cart';
import General from './views/general';
import Home from './views/home';
import SpecialCategory from './views/specialcategory';
import Navi from './views/navi';
import Content from './views/content';
import ReferToAFriend from './views/referToAFriend';
import Raffle from './views/raffle';
import Trackinghelper from './utils/trackinghelper';
import lazysizes from './utils/lazysizes';
import BrandsTeaser from './components/teaser/brands_teaser';
import SliderTeaser from './components/teaser/slider_teaser';
import Service from './components/teaser/service';
import ServiceTabScroll from './components/serviceTabScroll';
import FormValidator from './utils/formValidator';
import ShopForm from './views/shopform';
import Feedback from './views/feedback';
import DirectOrder from './views/directorder';
import ActionCodeEngine from './components/action_code_engine';
import AcceptCookies from './components/cookies';
import ContactForm from './components/contactform';
import NewsletterForm from './components/newsletter_form';
import Tracking_GA from './components/tracking_ga';
import CartChange from './components/cart_change';
import ShopRaffle from './components/shopraffle';
import ExitIntentLayer from './components/exit_intent_layer';
import RecommendationSwipe from './components/teaser/recommendation_swipe';
import PayPalPayments from './components/paypal_payments';
import Suggestions from './components/suggestions';
import InitFilterReset from './components/productlist_filters_reset';
import ProductListFilterSortByFlyout from './components/productlist_filter_sort_by_flyout';
import GenericFrontendModel from './components/generic_frontend_model';
import Phone from './components/phone';
import AddressSuggestions from './components/address_sugesstions';

export default function App() {}

App.prototype.initialize = function() {
  //js for the pages
  if ($(".art-detail-page").length != 0) {
    new Ads();
    new ReferToAFriend();
    new ServiceTabScroll();
  } else if ($(".account").length != 0) new Account();
  else if ($(".basket-layout").length != 0) new Cart();
  else if ($(".checkout").length != 0) new Checkout();
  else if ($(".home").length != 0) {
    new Home();
  }
  else if($(".no-search-result").length != 0) new Product();
  else if ($(".content").length != 0) new Content();

  if ($(".page-product-list").length != 0) new Product();
  if ($(".js-directorder").length != 0) new DirectOrder();
  if ($(".js-action-code-engine").length != 0) new ActionCodeEngine();

  if ($(".js-contact-form").length != 0) new ContactForm();
  if ($(".js-newsletter-form").length != 0) new NewsletterForm();
  if ($(".cart-product").length != 0) new CartChange();
  if ($(".shop-raffle").length != 0) new ShopRaffle();
  if ($(".recommendation .swipe").length != 0) new RecommendationSwipe();
  if ($(".js-special-category-overlay").length != 0) new SpecialCategory();
  if ($(".js-paypal").length != 0) new PayPalPayments();
  if ($(".js-suggestions-input").length != 0) new Suggestions();
  if ($(".js-phone").length != 0) new Phone();
  if ($(".js-address-suggestions").length != 0) {
    new AddressSuggestions();
  }

  if ($(".js-product-filters-burger-wrapper").length != 0 || $(".js-product-sort-by-burger-wrapper").length != 0) new ProductListFilterSortByFlyout();
  if ($(".js-product-list-filters-reset").length != 0) new InitFilterReset();

  // accordion behaviour for service teaser
  if ($(".accordion-teaser").length != 0) {
    new Service();
  }

  if ($(".js-cookie-layer").length != 0) {
    new AcceptCookies();
  }

  // TODO restrict loading to pages with a brands teaser.?.
  new BrandsTeaser();
  new SliderTeaser();
  new General();
  new Navi();
  new Trackinghelper();
  new Raffle();
  new Feedback();
  new Tracking_GA();
  new ExitIntentLayer();
  new GenericFrontendModel();

  // CSRF protection
  var token = $("meta[name='_csrf']").attr("content");
  var header = $("meta[name='_csrf_header']").attr("content");
  $.ajaxPrefilter(function( options, originalOptions, jqXHR ) {
    jqXHR.setRequestHeader(header, token);
  });

  //Do not edit below this line ;)
  //Subscribers can attach their events to the document
  Pubsub.publish("core.attach", document);

  //Register resolutions
  enquire.register("screen and (max-width:768px)", {
    match: function() {
      Pubsub.publish("res.xs", true);
    },
    unmatch: function() {
      Pubsub.publish("res.xs", false);
    }
  });
  enquire.register("screen and (min-width:769px) and (max-width:1024px)", {
    match: function() {
      Pubsub.publish("res.sm", true);
    },
    unmatch: function() {
      Pubsub.publish("res.sm", false);
    }
  });
  enquire.register("screen and (min-width:1025px) and (max-width:1199px)", {
    match: function() {
      Pubsub.publish("res.md", true);
    },
    unmatch: function() {
      Pubsub.publish("res.md", false);
    }
  });
  enquire.register("screen and (min-width:1200px)", {
    match: function() {
      Pubsub.publish("res.lg", true);
    },
    unmatch: function() {
      Pubsub.publish("res.lg", false);
    }
  });
};
