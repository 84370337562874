import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../components/loading';
import Html5Validation from '../utils/html5validation';


export default function DirectOrder() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
  this.loading = new Loading();
}

DirectOrder.prototype = {
  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    if($('.js-search-artno .single-size input:disabled')){
      $('.js-search-artno .single-size input:disabled').parent().addClass("disabled");
    }
    if($('.js-search-artno .single-size input[type=radio]:checked')){
      $('.js-search-artno .single-size input[type=radio]:checked').parent().addClass("active");
    }

    // show more fields
    $(".js-more-fields", element).off("click").on("click", function(e) {
      e.preventDefault();
      $('.js-hidden-fields').show();
      $(this).hide();
    });

    // prevent submit form on enter and tab
    $(".js-input-artno").off('keydown').on('keydown', function(e) {
      var keyCode = e.which;
      if (e.type == "keydown" && keyCode != 13 && keyCode != 9) {
        return;
      }
      e.preventDefault();
      $(this).parents('.article').find('.btn-search-artno').click();
    });

    // search article
    $(".btn-search-artno").off("click").on("click", function() {
      $(this).addClass('loader');
      var _this = this;
      var id = $(this).data('id');
      var itemId = $('#search-artno-'+ id).attr('value') ? $('#search-artno-'+ id).prop('value') : $('#search-artno-'+ id).val();
      if (itemId != 'undefined' && itemId != null) {
        var requestUrl = $('.js-directorder').data('add') + "?itemId="+ itemId + "&id="+ id;
        $.post(requestUrl, function (response) {
          $(".btn-search-artno").removeClass('loader');
          if(response.success) {
            $(_this).parent().next(".js-error").html('');
            $(_this).parents('.article').find(".js-search-artno").html(response.markup);
            if(id == 6){
              $('.js-hidden-fields').show();
              $('.js-more-fields').hide();
            }
            Pubsub.publish("core.attach", $(_this).parent().next(".js-search-artno"));
          } else {
            $(_this).parents('.article').find(".js-search-artno").html('');
            $(_this).parent().next(".js-error").html(response.markup);
          }
        }).fail(function (response) {
          $(this).removeClass('loader');
          window.console && console.log(response);
        })
      }else{
        $(this).removeClass('loader');
      }
    });

    // remove article
    $(".js-remove-article").off("click").on("click", function() {
      var _this = this;
      var article = $(_this).parents('.js-search-artno');
      var id = $(this).data('id');
      var itemIdSelector = $(this).parents('.article').find('.js-input-artno');
      var itemId = itemIdSelector.val();

      if (itemId != 'undefined' && itemId != null) {
        var requestUrl = $('.js-directorder').data('remove') + "?itemId="+ itemId + "&id="+ id;
        $.post(requestUrl, function (response) {
          $(itemIdSelector).val("");
          $(article).html("");
        }).fail(function (response) {
          window.console && console.log(response);
        })
      }
    });

    // add article from customer choice to direct order form
    $(".js-input-customers-choice").off("click").on("click", function(e){
      e.preventDefault();
      var itemid = $('#js-customers-choice-form').find('input[name=itemId]').val();
      var firstEmptyField = $('.js-directorder input.js-input-artno').filter(function(){return ($(this).val().length == 0) }).first();
      $('html,body').animate({scrollTop: firstEmptyField.offset().top});
      firstEmptyField.prop('value', itemid);
      firstEmptyField.parent().next().find('.btn-search-artno').click();
    });


    // send personal text to validate in backend
    $(".js-input-ptext").off('change').on('change', function(e) {
      var inputFieldSelector = this;
      var personalText = $(this).val();
      var lineIndex = $(this).data('lineindex');
      var posIndex = $(this).data('posindex');
      var requestUrl = $('.js-personaltext').data('add') + "?personalText="+ personalText + "&lineIndex=" + lineIndex + "&posIndex=" + posIndex;
      $.post(requestUrl, function (response) {
        if(!response.success) {
          $(inputFieldSelector).parents('.js-personaltext').find('.js-ptext-error').html(response.errorsMarkup);
          $(inputFieldSelector).addClass('has-error')
        }
      }).fail(function (response) {
        window.console && console.log(response);
      })
    });

    $('.js-search-artno select').off('change').on('change', function(e) {
      _this.editArticle(e, "isQty");
    });

    $('.article-form [data-update]').off('click').on('click', function(e) {
      _this.editArticle(e, "isSize");
    });

  },

  editArticle: function(e, type) {
    e.preventDefault();
    var $element = $(e.target);
    var size;
    var sizeName;
    var qty;
    if (type === "isQty") {
      size = $element.parents('.article-form').find('input.js-size:checked').val();
      sizeName = $element.parents('.article-form').find('input.js-size:checked').attr('name');
      qty = $element.val();
    } else {
      size = $element.find('.js-size').val();
      sizeName = $element.find('.js-size').attr('name');
      qty = $element.parents('.js-search-artno').find('.js-qty').val();
    }
    var id = $element.parents('.article-form').data('id');
    var previousItemId = $element.parents('.article-form').find('#input-itemId').serialize();
    var requestUrl = $('.js-directorder').data('change') + "?" + escape(sizeName) +"="+ size + "&qty="+ qty + "&id="+ id + '&' + previousItemId;
    $.post(requestUrl, function (response) {
      if(response.success) {
        $element.parents('.single-article').html(response.markup);
        if($('.js-search-artno.single-article input:checked')){
          $('.js-search-artno.single-article input:checked').parent().addClass("active");
        }
        Pubsub.publish("core.attach", $element.parent().next(".js-search-artno"));
      }else{
        $element.parents('.article').find('.js-error').html(response.markup);
      }
    }).fail(function (response) {
      window.console && console.log(response);
    })
  },

  responsive: function(msg, on_off) {
    switch (msg) {
    case 'res.xs':
      if (on_off) { // entered state xs
      } else { // left state xs

      }
      break;
    case 'res.sm':
      if (on_off) { // entered state sm
      } else { // left state sm

      }
      break;
    default:
      if (on_off) {
      }
    }
  }
};
