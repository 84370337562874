import $ from 'jquery';
import config from '../config/config';
import Pubsub from 'pubsub';

export default function init() {
  var _this = this;

  // set pickUpPoint address from selected radio button
  $('#shippingForm').off('change').on('change', 'input[name="indexOfSelectedPickUpPoint"]', function(e){
    $('input[name="pickUpPoint.id"]').val($(this).data('id'));
    $('input[name="pickUpPoint.shopName"]').val($(this).data('shopname'));
    $('input[name="pickUpPoint.street"]').val($(this).data('street'));
    $('input[name="pickUpPoint.streetNumber"]').val($(this).data('streetNumber'));
    $('input[name="pickUpPoint.city"]').val($(this).data('city'));
    $('input[name="pickUpPoint.zipCode"]').val($(this).data('zipcode'));
    $('input[name="pickUpPoint.country"]').val($(this).data('country'));
    var postData = {
        'indexPickUpPoint': $(this).data('id')
    };
    var url = config.shippingUrls.setPickUpPoint;
    $.post(url, postData);
  });


  // set address type on click
  $('input[name=addressType]').off('change').on('change', function(e){
    e.preventDefault();
    var postData = {
        'addressType': $(this).val()
    };
    var url = config.shippingUrls.setAddressType;
    $.post(url, postData);
    //close error message if showed
    if($("#shippingForm .form-error").is(":visible")) {
      $("#shippingForm .form-error").remove();
      $("#shippingForm .panel-group").removeClass("has-error");
    }
  });

  // set delivery type on click
  $('input[name=deliveryType]').off('change').on('change', function(e){
    e.preventDefault();
    var postData = {
        'deliveryType': $(this).val()
    };
    var url = config.shippingUrls.setDeliveryType;
    $.post(url, postData);
  });

  // set payment type on click
  $('input[name=paymentType]').off('change').on('change', function(e){
    e.preventDefault();
    var postData = {
        'paymentType': $(this).val()
    };

    var url = config.paymentUrl;
    $.ajax({
      type: 'POST',
      url: url,
      data: postData,
      dataType: 'json',
      async: false,
      success: function(data) {
        $('.js-payment-top-btn').html(data.markupTop);
        $('.js-payment-bottom-btn').html(data.markupBottom);
        Pubsub.publish("payment.paypal", document);
        $(".paypal-js-error-technical").hide();
        $(".paypal-js-error-general").show();
        $(".panel-default").removeClass('has-error');
        $(".panel-group").removeClass('has-error');
        $(".form-error").first().hide();
        $(".checkout-content").find(":button").attr("disabled",false);
        $("#saferpay-button").off("click").on("click", function(e) {
          $.get(cfg.saferpayPaymentPageInitializeUrl, function(data) {
            window.location.href = data.url;
          });
        });
      },
      fail: function (error) {
        if (window.console) {
          console.error("set payment type failed. ", error);
        }
      }
    });

    //close error message if showed
    if($("div.payment .form-error").is(":visible")) {
      $("div.payment .form-error").remove();
      $("div.payment .panel-group").removeClass("has-error");
    }
  });
}


