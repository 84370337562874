import $ from 'jquery';
import '../utils/lazyload';
import '../utils/slidercontrols';
import '../utils/sliderknobs';
import '../utils/sliderlazyload';
import 'swipe';
import Pubsub from 'pubsub';
import TrackingHelper from '../utils/trackinghelper';
import config from '../config/config';
import language from '../config/language';

export default function Home() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));
}

Home.prototype = {
  //"methods"
  attach: function(msg, element) {
    if (element === document) {
      //page load banner toggle
      $(".action-push").collapse({
        toggle: false
      });
      setTimeout(function() {
        $(".action-push").collapse("show");
        $(".action-push button").click(function(e) {
          return false;
        });
        $(".action-push button").mousedown(function(e) {
          e.preventDefault();
          $(".action-push").collapse("hide");
          //Tracking
          var trackinghelper = new TrackingHelper();
          $element = $(e.target);
          var category = $element.data('category');
          var action = $element.data('action');
          var value = $element.data('value');
          trackinghelper.event(category !== undefined ? category : '', action, value !== undefined ? value : '');
        });
      }, 1500);
    }

    if($('.home .recommendation .swipe-wrap .slide[data-index="0"] .product-grid-item:first-child .caption').length === 1){
      $('.home .recommendation .swipe-wrap .slide[data-index="0"] .product-grid-item:first-child .caption').attr('data-cerberus','hp-cross-sell-news-first-product-elements');
    }
    if($('.home .recommendation .swipe-wrap .slide[data-index="0"] .product-grid-item:first-child').length === 1){
      $('.home .recommendation .swipe-wrap .slide[data-index="0"] .product-grid-item:first-child').attr('data-cerberus','hp-cross-sell-news-first-product-block');
    }
    if($('.home .recommendation .right.carousel-control').length === 1){
      $('.home .recommendation .right.carousel-control').attr('data-cerberus','hp-cross-sell-news-slider-right-arrow');
    }
    if($('.home .recommendation .left.carousel-control').length === 1){
      $('.home .recommendation .left.carousel-control').attr('data-cerberus','hp-cross-sell-news-slider-left-arrow');
    }
  },
  responsive: function(msg, on_off) {
    switch (msg) {
    case 'res.xs':
      if (on_off) { // entered state xs
        this.removeSwipes();
        $(".action-stage .swipe").Swipe({
          auto: 6000,
          speed: 600
        }).controls().knobs();
        $(".teaser-carousel .swipe").Swipe({
          pair: 2
        });

      } else { // left state xs

      }
      break;
    case 'res.sm':
      if (on_off) { // entered state sm
        this.removeSwipes();
        var swipe = $(".action-stage .swipe").Swipe({
          auto: 6000,
          speed: 600
        }).knobs();

        // Add controls to 3Pagen modern with legacy breakpoint sm
        if($('.action-stage').closest('.x-style')){
          swipe.controls();
        }
        $(".teaser-carousel .swipe").Swipe({
          pair: 2
        });

      } else { // left state sm

      }
      break;
    default:
      if (on_off) {
        this.removeSwipes();
        $(".action-stage .swipe").Swipe({
          auto: 6000,
          speed: 600,
          transitionEnd: function(index, elem) {
            //Tracking
            if ($(elem).parent().hasClass('stoped')) {
              var trackinghelper = new TrackingHelper();
              var $element = $(elem).parents('.swipe');
              var category = $element.data('category');
              var action = $element.data('action');
              var value = $element.data('value');
              trackinghelper.event(category !== undefined ? category : '', action, value !== undefined ? value : '');
            }
          }
        }).controls().knobs();
        $(".teaser-carousel .swipe").Swipe({
          pair: 2
        });

        // action-stage left right arrow
        $(".action-stage > div").eq(1).hover(function() {
          $('.carousel-control', this).addClass('hover');
        }, function() {
          $('.carousel-control', this).removeClass('hover');
        });
      }
    }
  },
  removeSwipes: function() {
    $(".swipe").each(function() {
      if ($(this).data("Swipe")) $(this).data("Swipe").kill();
    });
  }
};
