import $ from 'jquery';
import ProductListFilters from '../components/productlist_filters';
import Pubsub from 'pubsub';
import Offcanvas from '../components/offcanvas';
import Pagination from '../components/pagination';
import LazyScroll from '../components/lazyscroll';
import 'bootstrap';

export default function Product() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.resxs, this));
  this.offcanvas = new Offcanvas();
  this.filters = new ProductListFilters();
}

Product.prototype = {
  attach: function(msg, element) {

    $("a.productlink").off("mousedown").on("mousedown", function(e) {
      //track product link click (with left and middle mouse buttons)
      if (e.which === 1 || e.which === 2) {
        var $this = $(this);
        Pubsub.publishSync("tracking.productListClick", $this);
      }
    });

    if ($(element).has(".product-list-part")) {
      this.lazyScroll = new LazyScroll(".product-list", ".product-grid-item");
    }

    //Form Toggle
    var toggleBtnFilter = $('.page-product-list [data-toggle=offcanvas][data-burgertype=filter]');
    var toggleBtnSortBy = $('.page-product-list [data-toggle=offcanvas][data-burgertype=sortby]');
    var _this = this;

    toggleBtnFilter.off("click").on("click", function(e) {
      e.preventDefault();
      e.stopPropagation();

      Pubsub.publish("offcanvas.showfilter", true);

      _this.offcanvas.toggle();
      $('#product-filter-flyout').attr("data-burger", "filter");
    });

    toggleBtnSortBy.off("click").on("click", function(e) {
      e.preventDefault();
      e.stopPropagation();

      Pubsub.publish("offcanvas.showsortby", true);

      _this.offcanvas.toggle();
      $('#product-filter-flyout').attr("data-burger", "sortby");
    });

    if ($("#s24widget")[0]) {
      $.getScript('http://widget.s24.com/v2/media/js/s24widget.js').done(function() {
        var sq = $(".searchquery").data('search-query');
        if (window.console) {
          console.log(sq);
        }
        $('#s24widget').s24_widget(
          {
            'app_id': '4fa76833',
            'widget_id': 10,
            'search_query': sq
          }
        );
      });
    }

    $('input:radio').click(function() {
      $('label[for="' + $(this).attr('id') + '"]').toggleClass('list-active');
    });

    this.pagination = $(".list-pager", element).pagination({
      container: $(".product-list-part")
    }).data("Pagination");

    //Close
    $(".product-filter-controls .close-btn", element).off("click").on("click", function(e) {
      e.preventDefault();
      _this.offcanvas.hide();
    });

    if (_this.isxs) {
      _this.offcanvas.switchIt(_this.isxs, true);
    } //Double from offcanvas, but needed for speed..
  }, //attach
  resxs: function(message, on_off) {
    if ((message == "res.xs" || message == "res.sm") && on_off) {
      this.isxs = on_off;
      this.offcanvas.switchIt(on_off);
    }
    else if ((message == "res.md" || message == "res.lg") && on_off) {
      this.isxs = false;
      this.offcanvas.switchIt(false);
    }
    //Re-Setup Swipes
    if (on_off) {
      $(".product-grid-item .swipe").each(function() {
        if ($(this).data("Swipe")) {
          $(this).data("Swipe").setup();
        }
      });
    }
  },
  enableRange: function(enable) {
    $("#from_price, #to_price").prop("disabled", !enable);
  }
};
