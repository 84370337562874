import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';
import { Validator } from '../utils/forms';
import ModalFns from '../utils/modalfns';

export default function ReferToAFriend() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('refer-to-a-friend.attach', $.proxy(this.attach, this));
}

ReferToAFriend.prototype = {
  attach: function(msg, element) {
    var _this = this;

    $("[href=#openReferToAFriend]").off("click").on("click", function(e) {
      e.preventDefault();
      _this.openModal(_this);
    });

    $("#submitReferToAFriend").off("click").on("click", function(e) {
      e.preventDefault();
      _this.submitForm(_this);
    });
  },

  openModal: function(_this) {
    $("#the-modal").empty().modal().load(config.referToAFriendModalUrl, function() {
      _this.showForm(_this);
      Pubsub.publish("refer-to-a-friend.attach", $("#the-modal"));
    });
  },

  showForm: function(_this) {
    $.get(config.referToAFriendFormUrl, function(data) {
      $("#refer-to-a-friend-content-anchor").empty().html(data);
      Pubsub.publish("refer-to-a-friend.attach", $("#refer-to-a-friend-content-anchor"));
    });
  },

  submitForm: function(_this) {
    var $form = $("form#referToAFriendForm");
    var validator = new Validator($form);
    validator.scrollToError = false;

    if (validator.validate(true)) {
      $.post(config.referToAFriendSubmitUrl, $form.serialize(), function(data) {
        $("#refer-to-a-friend-content-anchor").empty().html(data);
        Pubsub.publish("refer-to-a-friend.attach", $("#refer-to-a-friend-content-anchor"));
      });
    }
  },

};

// Extend Modal Functions
ReferToAFriend.prototype = Object.assign(ReferToAFriend.prototype, ModalFns.prototype);
