import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';
import language from '../config/language';

export default function SpecialCategory() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
}

SpecialCategory.prototype = {
  attach: function(msg, element) {

    if(element === document){
      $(document).ready(function() {
        var shopCountry = config.shopCountry;
        var isShopCountryEqualsLanguage = shopCountry === language.slice(-2).toLowerCase();
        // if language is different to shop country
        if(isShopCountryEqualsLanguage) {
          removeCountrySwitch();
        }
        if(isEmptyElement($('#the-modal'))) {
          showSpecialCategoryOverlay();
        }
      });
    }

    setTimeout(function () {
        $("#the-modal").off('hidden.bs.modal').on('hidden.bs.modal', function() {
          emptyModal();
          showSpecialCategoryOverlay();
        });
      }, 10);

    function showSpecialCategoryOverlay() {
      $.get(cfg.specialCategoryUrl, function (data) {
        if (data.markup && data.showSpecialCategoryOverlay) {
            $('#the-modal').html(data.markup);
            $.post(cfg.specialCategoryUrl, {"specialCategoryOverlayHasShown": true});
            $('#the-modal').modal('show');
        } else {
          emptyModal();
        }
      }).fail(function (jqXhr, error) {
        if (window.console) console.log("special category overlay error!", error);
      });
    }

    function removeCountrySwitch() {
      var countryOverlay = $('.js-country-switch');
      if (countryOverlay.length > 0) {
        countryOverlay.closest($('.modal-dialog')).remove();
      }
    }

    function emptyModal() {
      $("#the-modal").modal('hide');
      $("#the-modal").empty();
    }

    function isEmptyElement(element) {
      return !$.trim(element.html());
    }
  }
};
