import $ from 'jquery';
import '../utils/lazyload';
import '../utils/sliderlazyload';

export default function LazyScroll(containerSelector, elementSelector, options) {
  var defaults = {
    count: 25,
    padding: 300,
    controls: true
  };

  this.options = $.extend(defaults, options);
  this.containerSelector = containerSelector;
  this.elementSelector = elementSelector;
  this.controls = this.options.controls;

  this.kill();
  this.setup();
}

LazyScroll.prototype = {
  kill: function() {
    $(document).off("scroll");
    $(window).off("resize");
    $(this.elementSelector, this.container).find(".loaded").each(function() {
      var swipe = $(this).data("Swipe");
      if (swipe) swipe.kill();
    })
  },
  setup: function() {
    var _this = this;
    this.container = $(this.containerSelector);

    $(window).on("resize", function() {
      _this.windowHeight = $(window).height();
      _this.windowWidth = $(window).width();
    });
    $(window).on("scroll", function(e) {
      if (_this.container.length == 0) {
        _this.kill();
        return;
      }
      _this.scrollPos = $(document).scrollTop();
      _this.containerTop = _this.container.offset().top - _this.scrollPos;

      var loadit = false;
      var count = 0;

      if (_this.windowHeight > _this.containerTop) {
        $(_this.elementSelector, _this.container).not(".loaded").each(function() {
          if ($(this).offset().top - _this.scrollPos - _this.options.padding < _this.windowHeight) {
            loadit = true;
          }

          if (loadit) {
            if ($(this).find(".swipe").length > 0) {
              $(this).addClass("loaded").find(".swipe").Swipe({
                callback: _this.swipeCallbackClosure(this)
              }).lazy().controls().knobs({
                classes: ["hidden-md", "hidden-lg"]
              });
            }
            else $(this).addClass("loaded").lazyload();
            count++;
          }
          if (count >= _this.options.count) {
            return false;
          }
        });
      }

    });

    $(window).trigger("resize");
    $(window).trigger("scroll");
  },
  pageFromScroll: function(scroll) {
    var index = 0;
    $.each(this.pages, function() {
      if (this >= scroll) return false;
      index++;
    });
    return index;
  },
  swipeCallbackClosure: function(gridItem) {
    return function(pos) {
      var $currentSwipe = $(".swipe-wrap > div", gridItem).eq(pos);
      var $a = $(gridItem).find("a");
      var href = $currentSwipe.attr("data-href");
      if (href !== undefined) $a.attr("href", href);
    }
  }
};
