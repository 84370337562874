/**
* jQuery Plugin for adding knobs to swipejs sliders
**/
import $ from 'jquery';

var defaults = {
  classes: [""]
};

function Knobs($el, options) {
  this.options = Object.assign(defaults, options);
  this.slider = $el.data("Swipe");
  this.$el = $el;
  if (this.options.knobWrapper)
    this.container = this.options.knobWrapper;
  else
    this.container = this.$el;

  this.init();
  this.$el.bind("swipe.kill", $.proxy(this.kill, this));
}

Knobs.prototype = {
  init: function() {
    if (!this.slider) return;

    var _this = this;
    this.numSlides = this.slider.getNumSlides();

    if (this.numSlides == 1) {
      this.kill();
      return;
    }

    this.pos = this.slider.getPos();

    this.originalCallback = this.slider.options.callback;
    this.slider.options.callback = function(pos, elem){
      var parent = $(elem).parents('.swipe');
      parent.find(".carousel-indicators li").removeClass("active").eq(pos).addClass("active");
    }

    this.markup = $('<ol class="carousel-indicators ' + this.options.classes.join(" ") + '">');

    for (var i = 0; i < this.numSlides; i++) {
      this.markup.append($('<li class="' + ((this.pos == i) ? "active" : "") + '">'));
    }

    if (this.container.find(".carousel-indicators").length == 0) {
      this.container.append(this.markup);
      this.$el.data("Knobs", this);

      this.container.find(".carousel-indicators li").on('click', function(e) {
        e.preventDefault();

        _this.slider.slide(_this.container.find(".carousel-indicators li").index(this));
      });
    }
  },
  kill: function() {
    this.container.find(".carousel-indicators li").off('click')
    this.container.find(".carousel-indicators").remove();
    this.$el.unbind("swipe.kill");
  }
};

$.fn.knobs = function(options) {
  var matched = $(this);

  matched.each(function() {
    new Knobs($(this), options)
  });
  return matched;
}
