import $ from 'jquery';
import Pubsub from 'pubsub';
import config from '../config/config';
import CartInput from '../components/cart_input';

export default function Affiliate() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  this.cart_input = new CartInput();
}

Affiliate.prototype = {
  attach: function(msg, element) {
    var _this = this;
    if (element == document) {
      if (config.affiliateOn) {
        $("#the-modal").empty().modal().load(config.affiliatePath, function() {
          $("#the-modal a.coupon-btn").on("click", function(e) {
            e.preventDefault();
            _this.showAffiliate(this, _this);
          });
        });
      }
      $(".basket-element .affiliate-icon").on("click", function(e) {
        e.preventDefault();
        _this.showAffiliate(this, _this);
      });
    }
  },
  showAffiliate: function(e, _this) {
    $.get($(e).attr("href"), function(data) {
      _this.cart_input.input(data.markup, data.count, data.total, data.time, data.title);
      $(".basket-element").addClass("coupon-inside");
    }).fail(function(jqXhr, error) {
      if (window.console)
        console.log("Coupon input failed..", error);
    });
  }
};
