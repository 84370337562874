import $ from 'jquery';

export default function CartInput(popoverSelector) {
  this.popoverSelector = popoverSelector ? popoverSelector : ".cart-header";
}

CartInput.prototype = {

  hideCart: function() {
    $(this.popoverSelector).popover("hide").popover("destroy");
    $('.popover').remove();
  },

  input: function(data, count, total, time, title) {
    this.hideCart();

    var _this = this;
    var time = time ? time : 6000;
    var options = {
      content: data
    };

    if (title) {
      options.title = title;
    }

    clearTimeout(_this.timeout);

    if (this.popoverSelector.match(/^\.notepad/)) {
      $(".notepad-count").html(count).show();
    } else {
      $(".js-cart-count").html(count).show();
      $(".js-cart-sum").html(total);
    }
    $("html, body").animate({
      scrollTop: 0
    }, 500);

    var popoverSelector = this.popoverSelector;
    $(this.popoverSelector).on("shown.bs.popover", function() {

      if (popoverSelector.match(/notepad/)) {
        //the following cannot be set via css, because apparently bootstrap dynamically overwrites the coordinates upon opening the tooltip
        $(".popover .arrow").css("right", "0px"); //aligns the nose of the popover to the right, so that the tip points exactly to the notepad-icon

        var popEl = $(".popover").get(0);
        popEl.parentNode.removeChild(popEl);

        var inXS = $(".notepad-element").width() === 0; //width is set to 0 via css if in xs, thus, if width == 0, we are in a smartphone
        $(inXS ? "#page" : ".notepad-header-wrapper").get(0).appendChild(popEl); //attach the popover to page Div, if in smartphone, to notepad-element otherwise
        if (inXS) {
          popEl.style.left = "0"; //apparently the left from the css definition in header.less gets overwritten upon opening the tooltip
        }
      }
      var close = $('<button type="button" class="close">&times;</button>').on("click", function() {
        _this.hideCart();
        clearTimeout(_this.timeout);
      });

      var popoverTitle = $(this).next().find(".popover-title");
      if (popoverTitle.find("button").length == 0) popoverTitle.append(close);
    });

    $(this.popoverSelector).popover(options).popover("show");
    //$(".cart-header").popover(options).popover("show");

    _this.timeout = setTimeout(function() {
      _this.hideCart();
    }, time);

  }
};
