import $ from 'jquery';

export default function Html5Validation() {
  // body...
}

Html5Validation.prototype = {
  showValidationErrorMessage: function(element, message) {
    var errorField = $(element).closest('.validation-group').find('.form-error');
    if (message) {
      errorField.show();
      errorField.find('.msg').html(message);
    }
  },
  validateField: function(element) {
    var _this = this;
    var inputField = element.validity;
    var inputFieldIsVisible = $(element).is(":visible");
    var inputFieldName = $(element).attr("name");

    if ( !inputFieldIsVisible && inputFieldName.startsWith('_') ) {
      return true;
    }

    if(!inputField.valueMissing && $(element).hasClass('no-validate')){
      $(element).removeClass('has-error');
      $(element).closest('.validation-group').find('.form-error').hide();
      return true;
    }

    var matchingElement = $(element).data("validation-matching-field");
    var hasMatchingError = false;
    if (matchingElement !== undefined) {
      var matchingElementValue = $("#" + matchingElement).val();
      hasMatchingError = !(matchingElementValue === $(element).val());
    }
    if ((!inputField.valid || hasMatchingError) && inputFieldIsVisible) {
      if (hasMatchingError) {
        var $matchingElement = $("#" + matchingElement);
        _this.showValidationErrorMessage(element, $(element).data("error-nomatch"));
        _this.showValidationErrorMessage($matchingElement[0], $matchingElement.data("error-nomatch"));
      } else if (inputField.valueMissing) {
        _this.showValidationErrorMessage(element, $(element).data("error-valuemissing"));
      } else if (inputField.typeMismatch) {
        _this.showValidationErrorMessage(element, $(element).data("error-typemismatch"));
      } else if (inputField.patternMismatch) {
        _this.showValidationErrorMessage(element, $(element).data("error-patternmismatch"));
      } else if (inputField.tooLong) {
        _this.showValidationErrorMessage(element, $(element).data("error-toolong"));
      } else if (inputField.tooShort) {
        _this.showValidationErrorMessage(element, $(element).data("error-tooshort"));
      } else if (inputField.rangeUnderflow) {
        _this.showValidationErrorMessage(element, $(element).data("error-rangeunderflow"));
      } else if (inputField.rangeOverflow) {
        _this.showValidationErrorMessage(element, $(element).data("error-rangeoverflow"));
      } else if (inputField.stepMismatch) {
        _this.showValidationErrorMessage(element, $(element).data("error-stepmismatch"));
      } else if (inputField.badInput) {
        _this.showValidationErrorMessage(element, $(element).data("error-badinput"));
      } else if (inputField.customError) {
        _this.showValidationErrorMessage(element, $(element).data("error-customerror"));
      }
    } else {
      $(element).removeClass('has-error');
      $(element).closest('.validation-group').find('.form-error').hide();
      if (matchingElement !== undefined) {
        $("#" + matchingElement).closest('.validation-group').find('.form-error').hide();
      }
      return true;
    }

    $(element).addClass('has-error');
    return false;
  },
  addValidationHandler: function() {
    var _this = this;
    $("form.validate-form input, form.validate-form select, form.validate-form textarea").each(function() {
      var __this = this;
      $(this).blur(function() {
        _this.validateField(__this);
      });
    });
    $("form.validate-form input[type=checkbox]").each(function() {
      var __this = this;
      $(this).click(function() {
        _this.validateField(__this);
      });
    });
  },



  validateOnSubmit: function() {
    var _this = this;
    $("form.validate-form").submit(function(e) {
      if (!_this.validate($(this))) {
        $('html,body').animate({scrollTop: $('.has-error:first').offset().top});
        e.preventDefault();
      }
    });
  },

  validateOnAjaxSubmit: function(that) {
    var _this = this;
    if (!_this.validate(that)) {
      return true;
    } else {
      return false;
    }
  },

  validate: function(form) {
    var _this = this;
    var noError = true;

    form.find("input, select, textarea").each(function() {
      var fieldHasNoError = _this.validateField(this);
      noError = noError && fieldHasNoError;
    });

    return noError;
  }

};
