// Everything that should happen on every page
import $ from 'jquery';
import Pubsub from 'pubsub';
import 'bootstrap';
import Offcanvas from '../components/offcanvas';

export default function ProductListFilterSortByFlyout() {
  this.offcanvas = new Offcanvas('filter-offcanvas');
  Pubsub.subscribe('offcanvas', $.proxy(this.control, this));
  Pubsub.subscribe('datafilter', $.proxy(this.dataFilter, this));
}

ProductListFilterSortByFlyout.prototype = {
  control: function(msg, on) {
    const _this = this;
    if (on) {
      $('.js-navi-close').off('click').on('click', function(e) {
        e.preventDefault();
        $("body").removeClass("offcanvas");
        _this.offcanvas.hide();
      });
    }

    if (on && (msg === "offcanvas.showsortby")){
      $('.js-product-list-controls li, .js-product-list-controls li span').off('click').on('click', function(e) {
        e.preventDefault();

        const markedTarget = $("[data-marker='"+ e.target.dataset.target +"']")
        if(markedTarget.prop("checked") === false) {
          markedTarget.prop("checked", true);
          markedTarget.first().trigger("change");
          $("body").removeClass("offcanvas");
        }
      });
    }

    if (on && (msg === 'offcanvas.showfilter')) {

      // show submenu
      $('.js-product-list-filters-main-category').off('click').on('click', function(e) {
        e.stopPropagation();
        $('.product-filters-burger-offcanvas .menu-back-1').show();
        if ($(this).siblings().is('ul')) {
          $(this).next().addClass('shop-navigation-content').show();

          const targetType = $(e.target).data("categorietype");
          $('.menu-back-1 a span.js-description-' + targetType).removeClass("hidden");

          $('.js-product-filters-burger-mobile-header').addClass('hidden');
          $(this).closest('[data-js=product-filters-burger-menu-ul]').animate({
            left: '-=' + ($(this).next().width())
          }, 'fast');
          $("html, body").animate({
            scrollTop: 0
          }, 200);
          return false;
        }
      });

      // submenu click function
      $('.product-filter-button-list-submenu li, .product-filter-button-list-submenu li span').off('click').on('click', function(e) {
        e.preventDefault();


        const markedTarget = $("[data-marker='"+ e.target.dataset.target +"']")
        if(e.target.dataset.istype === 'radio' && markedTarget.prop("checked") === false) {
          $(".js-single-checked").each(function () {
            if ($(this).prop("checked")) {
              $(this).prop("checked", false);
            }
          });
        }
        if (markedTarget.prop("checked")) {
          markedTarget.prop("checked", false);
        } else {
          markedTarget.prop("checked", true);
        }
        markedTarget.first().trigger("change");

        // $("body").removeClass("offcanvas");
      });

      // back menu level
      const goBack = function(e) {
        e.preventDefault();
        const $backBtn = $(this);
        $('.product-filters-burger-offcanvas .menu-back-1').hide();
        $('.js-description-farbe, .js-description-PRICE, .js-description-SALE').addClass("hidden");

        $(this).parents('[data-js=product-filters-burger-menu-ul]').animate({
          left: '+=' + ($('.product-filters-burger-offcanvas ul ul:visible').first().width())
        }, 'fast', function() {
          if ($backBtn.hasClass('menu-back-2')) $('.product-filters-burger-offcanvas ul ul ul:visible').hide();
          else {
            $('.js-product-filters-burger-mobile-header').removeClass('hidden');
            $('.product-filters-burger-offcanvas ul ul:visible').hide();
          }
        });
        $("html, body").animate({
          scrollTop: 0
        }, 200);
      };
      $('.menu-back-1, .menu-back-2').off('click').on('click', goBack);
    }
  },
  dataFilter: function(msg, on) {
    if (on) {
      $('#product-filter-flyout').attr("data-burger", "filter");
    }
  }
};
